import { UserState, OverlayUser } from '@/store';

import Vue from 'vue';
import { OverlayUserPrefsStorage, OverlayUserPrefsPayload } from './types';

import omit from 'lodash/omit';

// Store details about the currently logged in use
export const userMeSet = (state: UserState, me: OverlayUser): void => {
  /* append/overwrite keys rather than replace values completely
     this is so reporting and requests can work together as they both supply
     different user objects */
  Object.keys(me).forEach((key) => {
    state.me[key] = me[key];
  });
};

// Set user preferences in device storage
export const userPreferencesLoad = (
  state: UserState,
  userPrefs: OverlayUserPrefsStorage,
): void => {
  state.preferences = {
    ...userPrefs,
  };
  localStorage.setItem('userPreferences', JSON.stringify(state.preferences));
};

// Set user preferences in device storage
export const userPreferencesSet = (
  state: UserState,
  { userPrefs, userPrefsNamespace }: OverlayUserPrefsPayload,
): void => {
  const currentPrefs = JSON.parse(
    localStorage.getItem('userPreferences') || '{}',
  );
  Vue.set(state, 'preferences', {
    ...currentPrefs,
    [userPrefsNamespace]: {
      ...currentPrefs[userPrefsNamespace],
      ...userPrefs,
    },
  });
  localStorage.setItem('userPreferences', JSON.stringify(state.preferences));
};

// Clear user preferences in device storage by namespace
export const userPreferencesClear = (
  state: UserState,
  userPrefsNamespaces: string[],
): void => {
  Vue.set(state, 'preferences', {
    ...omit(
      JSON.parse(localStorage.getItem('userPreferences') || '{}'),
      userPrefsNamespaces,
    ),
  });
  localStorage.setItem('userPreferences', JSON.stringify(state.preferences));
};
