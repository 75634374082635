import { PubSubState, PubSubSubscription } from './types';
import { toConsistentHash } from './helpers';

export const subscriptionsList = (state: PubSubState): PubSubSubscription[] => {
  return Object.values(state.byHash);
};

export const subscriptionsByHash =
  (state: PubSubState) =>
  (hash: string): PubSubSubscription | undefined => {
    return state.byHash[hash];
  };

export const subscriptionsByTags =
  (state: PubSubState) =>
  (tags: string[]): PubSubSubscription | undefined => {
    const hash = toConsistentHash(tags);
    return subscriptionsByHash(state)(hash);
  };
