




















































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import Vue from 'vue';
import { mapActions } from 'vuex';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import every from 'lodash/every';

import { OAppPage, OPageRenderer, OPageConfig, OActions } from '@/mixin';
import { today, dayRange, dateFormat, isToday } from 'i8-ui';

export default Vue.extend({
  mixins: [OAppPage, OPageRenderer, OPageConfig, OActions],

  data() {
    return {
      externalData: {},
    };
  },

  computed: {
    filter: {
      get(): any {
        const vm = this as any;
        return vm.$route.query;
      },
      set(query: Record<string, any>): void {
        if (isEqual(query, this.$route.query)) {
          return;
        }

        if (isEmpty(this.$route.query)) {
          // if we store a history entry here, we'll never
          // be able to go back beyond this page since we
          // add a default date if one doesn't already exist
          this.$router.replace({ query });
        } else {
          this.$router.push({ query });
        }
      },
    },

    /**
     * Page header breadcrumb items
     */
    headerItems(): any[] {
      const vm = this as any;
      return [{ type: 'text', text: vm.pageName }];
    },

    /**
     * Context object for the renderer.
     *
     * Used to access data from i8-renderer schemas.
     *
     * @return { Object }
     */
    context(): object {
      const vm = this as any;
      return {
        isToday: vm.isToday,
        ...this.externalData,
        filter: this.filter,
      };
    },

    titleOptions(): object {
      const dateFrom = this.$route.query.from as string;
      return {
        // this dashboard is designed to show data for a single day only
        value: dateFormat(dateFrom, { format: 'YYYY-MM-DD' }),
        format: 'iso',
        options: this.filterOptions,
      };
    },

    /**
     * Flatpickr date options
     */
    filterOptions(): object {
      return {
        // disable dates in the future
        // disable: [(date: Date) => date > new Date()],
      };
    },

    /**
     * Return true if external data is emtpy or all properties in it are empty
     */
    hasNoData(): boolean {
      return every(this.externalData, isEmpty);
    },

    /**
     * Returns true if the current filter date is today
     */
    isToday(): boolean {
      const vm = this as any;
      return isToday(vm.filter.from);
    },
  },

  methods: {
    ...mapActions('config', ['viewSchemaLoad']),
    ...mapActions('document', ['documentsByTypeLoad']),

    selectDate(date: string): void {
      const range = dayRange(date);
      this.filter = { ...this.filter, from: range[0], to: range[1] };
    },

    /**
     * Load configuration data needed for the dashboard.
     *
     * Includes:
     *   - i8-renderer schema used for the layout
     *
     * @return { Promise<void> }
     */
    async loadData(): Promise<void> {
      const vm = this as any;

      try {
        vm.loadingStart();

        if (!vm.screenConfig) {
          // load document list template
          await vm.viewSchemaLoad({
            viewId: this.$route.name,
            parentViewId: this.$route.meta.parentId,
          });
        }

        await this.loadExternalData();
      } catch (error) {
        vm.loadingError(error);
      } finally {
        vm.loadingComplete();
      }
    },

    /**
     * Load data from the `dataSrc` section of view config
     */
    async loadExternalData(): Promise<void> {
      const vm = this as any;
      const config = vm.screenConfig;

      if (!config) {
        return;
      }

      for (const key in vm.dataSrc) {
        if (!Object.prototype.hasOwnProperty.call(vm.dataSrc, key)) {
          // inherited prop
          continue;
        }

        const dataSrc = vm.dataSrc[key];

        switch (dataSrc.type) {
          case 'plugin.intent':
            // eslint-disable-next-line no-case-declarations
            const data = await vm.intentEnqueue(dataSrc.intent);
            this.$set(this.externalData, key, data);
            break;

          default:
            console.warn('unsupported dataSrc', dataSrc);
            break;
        }
      }

      // re-run the schema pre-processor
      const dashboard = this.$refs.dashboard as any;
      if (dashboard) {
        dashboard.loadExternalData();
      }

      const titleActions = this.$refs.titleActions as any;
      if (titleActions) {
        titleActions.loadExternalData();
      }
    },
  },

  watch: {
    $route: {
      async handler() {
        if (
          !Object.prototype.hasOwnProperty.call(this.filter, 'from') ||
          !Object.prototype.hasOwnProperty.call(this.filter, 'to')
        ) {
          const range = dayRange(today());
          this.filter = { ...this.filter, from: range[0], to: range[1] };
        } else {
          await this.loadData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
});
