













import Vue from 'vue';

import { I8Icon } from 'i8-ui';

// Icon library
import { faSurprise } from '@fortawesome/pro-light-svg-icons/faSurprise';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSurprise);

const OError = Vue.extend({
  name: 'o-error',

  components: {
    I8Icon,
  },

  props: {
    error: {
      type: String,
    },
  },
});

export { OError };
export default OError;
