





































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { I8Icon } from 'i8-ui';

// Icon library
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faLockAlt);

export default Vue.extend({
  name: 'auth.error',

  components: {
    I8Icon,
  },

  data() {
    return {
      defaultMsg: 'Please contact your system administrator.',
    };
  },

  computed: {
    ...mapGetters('config', ['isWhitelabel', 'themeName']),

    message(): string {
      return (this.$route.query.message as string) || this.defaultMsg;
    },
    showLogout(): boolean {
      return !!(
        this.$route.query.showLogout && this.$route.query.showLogout === 'true'
      );
    },
  },

  methods: {
    ...mapActions('auth', ['logoutFromProvider']),

    async doLogout(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      await vm.logoutFromProvider();
    },
  },
});
