import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { FileState } from '@/store';

export * from './types';

export const file: Module<FileState, unknown> = {
  namespaced: true,
  state: {
    files: [],
  } as FileState,
  actions,
  mutations,
  getters,
};

export type FileGetters = typeof getters;
export type FileActions = typeof actions;
export type FileMutations = typeof mutations;
