import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { ConfigState } from '@/store';

export const config: Module<ConfigState, unknown> = {
  namespaced: true,
  state: {
    applicationMeta: {
      title: 'Overlay+',
    },
    globalNav: {
      topItems: [],
      bottomItems: [],
    },
    ui: {},
    view: {},
    eventTypes: [],
    screenSchema: {},
    eventBodySchema: {},
    eventFooterSchema: {},
    globalRenderContext: {},
  },
  actions,
  mutations,
  getters,
};

export * from './types';
export type ConfigGetters = typeof getters;
export type ConfigActions = typeof actions;
export type ConfigMutations = typeof mutations;
