




















import Vue from 'vue';

import { I8Icon } from 'i8-ui';

// Icon library
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCloudUpload, faFolderOpen);

const OFileManagerEmtpy = Vue.extend({
  name: 'o-file-manager-emtpy',

  components: {
    I8Icon,
  },

  props: {
    error: {
      type: String,
    },
    fileUpload: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      string: {
        noFilesMsg: 'Nothing here',
        fileUploadMsg: 'Drag files here to upload',
      },
    };
  },
});

export { OFileManagerEmtpy };
export default OFileManagerEmtpy;
