
























/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { I8PageNav } from 'i8-ui';

// Lib
import { OAppPage, OPageConfig } from '@/mixin';
import { AuthProvider } from '@/service';
import { AuthSession } from '@/store';

export default Vue.extend({
  mixins: [OAppPage, OPageConfig],
  components: {
    I8PageNav,
  },

  computed: {
    ...mapGetters('config', [
      'viewSchemaGet',
      'viewConfig',
      'globalRenderContext',
    ]),

    /**
     * Context object for the renderer.
     *
     * Used to access data from i8-renderer schemas.
     *
     * @return { Object }
     */
    context(): object {
      const vm = this as any;

      const context: Record<string, object> = {};

      if (vm.screenConfig) {
        context.viewContext = vm.screenConfig.viewContext || {};
      }

      return context;
    },

    fullWidth(): boolean {
      return !!this.$route.meta.fullWidth;
    },

    /**
     * Array of I8ContextItems to show in the page header
     *
     * @return { Array } I8ContextItem[]
     */
    headerItems(): any[] {
      const vm = this as any;
      const items = [] as any[];

      if (!vm.screenConfig) {
        // we're not ready yet
        return items;
      }

      // the title of this page is a simple text item
      items.push({
        type: 'text',
        text: vm.pageName,
      });

      // TODO: refactor views into a tree structure
      //       build up breadcrumbs by traversing the tree
      //       make this calculation generic and add to o-app-page

      return items.reverse();
    },

    /**
     * Array of I8ContextItems to show in the page header
     *
     * TODO: standarside this when we revisit breadcumbs
     *
     * @return { Array } I8ContextItem[]
     */
    breadcrumbs(): object[] {
      const vm = this as any;
      const items: object[] = [];

      const result = vm.getConfigItem('breadcrumbs');
      if (result) {
        return result;
      }

      // this changes depending on if we're in a tabbed view or not
      const viewId = this.$route.meta.parentId
        ? this.$route.meta.parentId
        : this.$route.name;

      const view = vm.viewConfig(viewId);

      if (!view) {
        return items;
      }

      const viewConfig = vm.parentConfig(view);

      items.push({
        type: 'text',
        text: viewConfig && viewConfig.name,
      });

      let parentView = vm.viewConfig(view.parentViewId);
      while (parentView) {
        const parentConfig = vm.parentConfig(parentView);

        items.unshift({
          type: 'link',
          text: parentConfig && parentConfig.name,
          location: {
            name: parentView.id,
          },
        });

        parentView = vm.viewConfig(parentView.parentViewId);
      }

      return items;
    },

    navItems(): any[] {
      const vm = this as any;
      const result = vm.getConfigItem('navItems') as any[];
      if (!result) return [];
      const allowsUserManagemnet = vm.allowsUserManagement(vm.$store);
      return result.filter(
        (item) =>
          item.id !== 'settings.overview' &&
          !(item.id == 'settings.users' && !allowsUserManagemnet),
      );
    },

    /**
     * Configuration object for this view.
     *
     * This is loaded from a JSON file referenced in config.json.
     *
     * @return { Object }
     */
    /* eslint-disable @typescript-eslint/no-explicit-any */
    config(): any {
      const vm = this as any;
      const config = vm.viewConfig(this.$route.meta.parentId);
      return vm.preprocessConfig(config);
    },
  },

  methods: {
    ...mapActions('config', ['viewSchemaLoad']),

    /**
     * Load all data needed for this view.
     *
     * Includes:
     *   - Config for each configured tab
     *
     * @return { Promise<void> }
     */
    async loadData(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      try {
        vm.loadingStart();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.config.views.forEach(async (view: any) => {
          if (!vm.viewSchemaGet(view.id)) {
            await vm.viewSchemaLoad({
              viewId: view.id,
              parentViewId: this.$route.meta.parentId,
            });
          }
        });

        // load document list template
      } catch (error) {
        vm.loadingError(error);
      } finally {
        vm.loadingComplete();
      }
    },
    allowsUserManagement(store: any): boolean {
      const authSession = store.getters['auth/session'] as AuthSession;
      const providers: AuthProvider[] = store.state.auth.providers;
      let allow = false;

      providers.forEach((p) => {
        if (p.id == authSession.provider) {
          allow = p.allows_user_management;
        }
      });
      return allow;
    },
  },
  watch: {
    /**
     * When out config changes, load all data for this view.
     *
     * This is done in a watcher instead of a lifecycle hook to work around
     * cases where vue re-uses the component instance.
     */
    config: {
      handler(newConfig) {
        if (!newConfig || !newConfig.views) {
          return;
        }

        this.loadData();
      },
      immediate: true,
    },
  },
});
