export const BTN_PRIMARY = 'primary';
export const BTN_SECONDARY = 'secondary';
export const BTN_TERTIARY = 'tertiary';
export const BTN_LINK = 'link';
export const BTN_WARNING = 'warning';
export const BTN_ICON = 'icon';

export const BTN_TYPE = [
  BTN_PRIMARY,
  BTN_SECONDARY,
  BTN_TERTIARY,
  BTN_LINK,
  BTN_WARNING,
  BTN_ICON,
];
