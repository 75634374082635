import {
  DocumentState,
  DocumentEvents,
  DocumentsByType,
  OverlayDocument,
  DocumentEvent,
} from '@/store';
import castArray from 'lodash/castArray';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import identity from 'lodash/identity';
import orderBy from 'lodash/orderBy';
import sortedUniqBy from 'lodash/sortedUniqBy';

import Vue from 'vue';

// Store a document by id
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const documentsByIdSet = (state: DocumentState, document: any): void => {
  // is this a new document?
  const isNewDoc = !state.documentsById[document.id];

  // is this a new version of an existing document?
  const isNewVersion =
    !isNewDoc && document.version > state.documentsById[document.id].version;

  if (isNewDoc || isNewVersion) {
    Vue.set(state.documentsById, document.id, document);
  }

  const docTypes = state.documentsByType[document.type];
  if (!docTypes || !Array.isArray(docTypes.documents)) {
    // this is a new document type so we don't need to update an existing record
    return;
  }

  const docIndex = findIndex(docTypes.documents, { id: document.id });
  if (docIndex < 0) {
    // if the document is not found, docIndex will be -1
    return;
  }

  const prevDoc = state.documentsByType[document.type].documents[docIndex];
  if (document.version > prevDoc.version) {
    // we don't want to accidentally go back a version
    Vue.set(state.documentsByType[document.type].documents, docIndex, document);
  }
};

// Store a list of documents by type
export const documentsByTypeSet = (
  state: DocumentState,
  options: DocumentsByType,
): void => {
  const { documentType, ...rest } = options;
  let { documents } = options;
  // remove any null values from the array
  documents = filter<OverlayDocument>(documents, identity);
  Vue.set(state.documentsByType, documentType, { ...rest, documents });
};

// Store a list of documents by type
export const documentEventsSet = (
  state: DocumentState,
  { documentId, events, next }: DocumentEvents,
): void => {
  // remove any null values from the array
  events = filter<DocumentEvent>(events, identity);
  Vue.set(state.documentEvents, documentId, { events, next });
};

// Append to an existing list of events
export const documentEventsAdd = (
  state: DocumentState,
  { documentId, events, next }: DocumentEvents,
): void => {
  const documentEvents = state.documentEvents[documentId];
  if (!documentEvents || !Array.isArray(documentEvents.events)) {
    // if we don't already have a list of events, we don't want to start one.
    return;
  }

  // ensure we have an array of events so we can use `Array.concat`
  const eventArr = castArray(events);

  // grab events for this document and add the new one at the end
  const eventList = state.documentEvents[documentId].events.concat(eventArr);

  // ensure events are correctly ordered by document version
  const orderedEvents = orderBy(eventList, ['version'], ['desc']);

  // ensure we remove duplicate events
  const uniqueEvents = sortedUniqBy(orderedEvents, 'version');

  // save the new event list
  Vue.set(state.documentEvents[documentId], 'events', uniqueEvents);

  if (next) {
    Vue.set(state.documentEvents[documentId], 'next', next);
  }
};
