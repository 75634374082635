import { Api } from '@/service/api.service';
import { PluginService, PagedOverlayApplications } from '@/service/types';
import { AxiosResponse } from 'axios';

// Prefix for all application requests
const resource = '/applications';

export const Plugin = {
  // Get all configured plugins config
  async getAll(): Promise<AxiosResponse<PagedOverlayApplications>> {
    return Api.get(`${resource}`);
  },
} as PluginService;
