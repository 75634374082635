import { DOCUMENT_CHANGED, DOCUMENT_CLEARED, TIMELINE_ACTION } from './types';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { Postmate } from '../../lib/postmate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const ParentPlugin = (store: any): void => {
  console.warn(
    'ParentPlugin is deprecated, please migrate to OverlayVuexPlugin.',
  );

  const handshake = new Postmate.Model({
    // Get updated when the document context changes
    document: (document: string) => {
      // Current document, document type etc
      // console.log('Document from parent', document);
      try {
        if (isUndefined(document) || isEmpty(document)) {
          store.dispatch(`parent/${DOCUMENT_CLEARED}`);
        } else {
          const d = JSON.parse(document);
          store.dispatch(`parent/${DOCUMENT_CHANGED}`, { document: d });
        }
      } catch (exp) {
        console.error(window.location.host, 'Plugin:Document', document, exp);
      }
    },
    // Action messages are sent from panels in the main ui
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: (message: any) => {
      try {
        store.dispatch(`parent/${TIMELINE_ACTION}`, {
          action: JSON.parse(message),
        });
      } catch (exp) {
        console.error(window.location.host, 'Plugin:Action', message, exp);
      }
    },
  });

  // When parent <-> child handshake is complete, events may be emitted to the parent
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handshake.then((parent: any) => {
    // eg: Send an "action complete" message
    //     advanced actions might request multiple input on the timeline
    //     think how slack does this form the server, we can do this in
    //     the client if the API is well built enough
    parent.emit('APP_READY', JSON.stringify({}));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    store.subscribeAction((action: any, state: any) => {
      switch (action.type) {
        // case `parent/${DOCUMENT_CHANGED}`:
        // case `parent/${DOCUMENT_CLEARED}`:
        // case `parent/${TIMELINE_ACTION}`:
        // do nothing for events which come from the parent
        // break;
        case `parent/ACTION_RESPONSE`:
          parent.emit('ACTION_RESPONSE', JSON.stringify(action));
          break;
        case `parent/DOCUMENT_ENRICHED`:
          parent.emit('DOCUMENT_ENRICHED', JSON.stringify(action));
          break;
        default:
          console.info(window.location.host, 'subscribeAction', action);
          break;
      }
    });
  });
};
