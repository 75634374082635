import './polyfills';
import '@/style/main.scss';
import { globalComponents } from '@/component';
import { router } from '@/router';
import { store } from '@/store';
import App from './app.vue';
import Vue, { PluginFunction } from 'vue';

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import Focus from '@/directive/focus';
Vue.use(Focus);

import { Overlay } from '@overlay/module-sdk';
Vue.use(Overlay.init as PluginFunction<unknown>, {
  'i8-ui': require('i8-ui'),
});

// global components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
globalComponents.forEach((component: any) => {
  Vue.component(component.options.name, component);
});

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (h: any) => h(App),
});

app.$mount('#app');
