import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';
import { PubSubState } from './types';
export * from './types';

export const pubsub: Module<PubSubState, unknown> = {
  namespaced: true,
  state: {
    byHash: {},
    status: 'closed',
  } as PubSubState,
  actions,
  mutations,
  getters,
};

export type PubSubGetters = typeof getters;
export type PubSubActions = typeof actions;
export type PubSubMutations = typeof mutations;
