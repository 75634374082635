import { OverlayState, ONotification, AppNavItem } from '@/store';

export const nextNotification = (
  state: OverlayState,
): ONotification | undefined => {
  return state.notifications[0];
};

export const isReady = (state: OverlayState): boolean => {
  return state.isReady;
};

export const dialogByNavItem = (state: OverlayState): AppNavItem => {
  return state.dialogByNavItem;
};
