/* eslint-disable @typescript-eslint/no-use-before-define */
import { ConfigState, AppNavConfig, EventTypeConfig, UIConfig } from './types';

import Vue from 'vue';
import findIndex from 'lodash/findIndex';
import { View } from '@overlay/module-sdk';

export const uiConfigSet = (state: ConfigState, ui: UIConfig): void => {
  Vue.set(state, 'ui', ui);
};

export const themeSet = (state: ConfigState, themeName: string): void => {
  Vue.set(state.ui, 'theme', { ...state.ui.theme, themeName });
};

// Save the top section nav items
export const appNavConfigSet = (
  state: ConfigState,
  globalNav: AppNavConfig,
): void => {
  if (!globalNav) {
    console.warn('empty global nav config', globalNav);
    return;
  }

  if (globalNav.topItems) {
    // nav items need an id to show icons properly
    addIds(globalNav.topItems, 'top');
  }

  if (globalNav.bottomItems) {
    // nav items need an id to show icons properly
    addIds(globalNav.bottomItems, 'bottom');
  }

  state.globalNav = globalNav;
};

// Save config for application metadata
export const appMetaConfigSet = (
  state: ConfigState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  applicationMeta: any,
): void => {
  state.applicationMeta = applicationMeta;
};

// Save the config for a list of document types
export const appViewConfigSet = (state: ConfigState, views: View[]): void => {
  if (!views) {
    console.warn('empty view config', views);
    return;
  }

  views.forEach((view) => {
    Vue.set(state.view, view.id, view);
  });
};

// Save a render schema for a screen and doc type
export const viewSchemaSet = (
  state: ConfigState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  { viewId, schema }: any,
): void => {
  Vue.set(state.screenSchema, viewId, schema);
};

// Save config for a list of event types
export const eventConfigListSet = (
  state: ConfigState,
  eventTypes: EventTypeConfig[],
): void => {
  state.eventTypes = eventTypes;
};

// Save config for the global render context
export const globalRenderContextSet = (
  state: ConfigState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  globalRenderContext: Record<string, any>,
): void => {
  state.globalRenderContext = globalRenderContext;
};

// Save config for a single event type
export const eventConfigSet = (
  state: ConfigState,
  eventType: EventTypeConfig,
): void => {
  const index = findIndex(state.eventTypes, {
    type: eventType.type,
  });

  if (index >= 0) {
    // Replace with updated config
    state.eventTypes.splice(index, 1, eventType);
  } else {
    // add new screen config
    state.eventTypes.push(eventType);
  }
};

// Save a render schema for an event
export const eventBodySchemaSet = (
  state: ConfigState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  { eventType, schema }: any,
): void => {
  Vue.set(state.eventBodySchema, eventType, schema);
};

// Save a render schema for an event
export const eventFooterSchemaSet = (
  state: ConfigState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  { eventType, schema }: any,
): void => {
  Vue.set(state.eventFooterSchema, eventType, schema);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addIds = (items: any[], prefix?: string) => {
  for (let i = 0; i < items.length; i++) {
    items[i].id = `${prefix}-${i}`;
  }
};
