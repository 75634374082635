// Icon library
import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons/faBadgeCheck';
import { faBallotCheck } from '@fortawesome/pro-light-svg-icons/faBallotCheck';
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faBookSpells } from '@fortawesome/pro-light-svg-icons/faBookSpells';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faCommentCheck } from '@fortawesome/pro-light-svg-icons/faCommentCheck';
import { faCommentTimes } from '@fortawesome/pro-light-svg-icons/faCommentTimes';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';
import { faEraser } from '@fortawesome/pro-light-svg-icons/faEraser';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { faFileCode } from '@fortawesome/pro-light-svg-icons/faFileCode';
import { faFileImport } from '@fortawesome/pro-light-svg-icons/faFileImport';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons/faFileInvoice';
import { faFilePlus } from '@fortawesome/pro-light-svg-icons/faFilePlus';
import { faMailbox } from '@fortawesome/pro-light-svg-icons/faMailbox';
import { faRedo } from '@fortawesome/pro-light-svg-icons/faRedo';
import { faTimesHexagon } from '@fortawesome/pro-light-svg-icons/faTimesHexagon';
import { faTools } from '@fortawesome/pro-light-svg-icons/faTools';
import { faUsersMedical } from '@fortawesome/pro-light-svg-icons/faUsersMedical';

import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faBadgeCheck,
  faBallotCheck,
  faBan,
  faBookSpells,
  faCheckCircle,
  faCommentCheck,
  faCommentTimes,
  faEdit,
  faEnvelopeOpenText,
  faEraser,
  faExclamationTriangle,
  faEyeSlash,
  faFileAlt,
  faFileCheck,
  faFileCode,
  faFileImport,
  faFileInvoice,
  faFilePlus,
  faMailbox,
  faRedo,
  faTimesHexagon,
  faTools,
  faUsersMedical,
);
