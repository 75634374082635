import trim from 'lodash/trim';
import sha3 from 'crypto-js/sha3';

export function toConsistentHash(tags: string[]): string {
  const normalised = tags
    .map((t: string) => trim(t.toLowerCase()))
    .sort()
    .join(',');
  return sha3(normalised).toString();
}
