/* eslint-disable @typescript-eslint/no-explicit-any */
import { OverlayContext, ONotification, AppNavItem } from '@/store';

export const notificationCreate = async (
  ctx: OverlayContext,
  notification: ONotification,
): Promise<void> => {
  if (notification) {
    ctx.commit('notificationEnqueue', notification);
  }
};

export const notificationDequeue = async (
  ctx: OverlayContext,
): Promise<void> => {
  ctx.commit('notificationDequeue');
};

export const isReadySet = async (
  ctx: OverlayContext,
  isReady: boolean,
): Promise<void> => {
  ctx.commit('isReadySet', isReady);
};

export const alert = async (
  ctx: OverlayContext,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  options: any,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    ctx.commit('alert', { options, resolve, reject });
  });
};

export const confirm = async (
  ctx: OverlayContext,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  options: any,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    ctx.commit('confirm', { options, resolve, reject });
  });
};

export const modal = async (
  ctx: OverlayContext,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  options: any,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    ctx.commit('modal', { options, resolve, reject });
  });
};

export const dialogResolve = async (
  ctx: OverlayContext,
  resolution: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<void> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (ctx.state.dialog.resolve) {
      await ctx.state.dialog.resolve(resolution);
      resolve();
    } else {
      reject('Nothing to resolve for dialog');
    }
    ctx.commit('dialogDestroy');
  });
};

export const setFavicon = async (
  ctx: OverlayContext,
  favicon: string,
): Promise<void> => {
  ctx.commit('favicon', { favicon });
};

/**
 * Update triggered by navItem from global nav
 */
export const updateDialogByNavItem = async (
  ctx: OverlayContext,
  navItem: AppNavItem,
): Promise<void> => {
  ctx.commit('updateDialogByNavItem', navItem);
};

/**
 * Clear triggered by navItem
 */
export const clearDialogByNavItem = async (
  ctx: OverlayContext,
): Promise<void> => {
  ctx.commit('updateDialogByNavItem', {} as AppNavItem);
};
