import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { DocumentState } from '@/store';

export * from './types';

export const document: Module<DocumentState, unknown> = {
  namespaced: true,
  state: {
    documentsByType: {},
    documentsById: {},
    documentEvents: {},
  } as DocumentState,
  actions,
  mutations,
  getters,
};

export type DocumentGetters = typeof getters;
export type DocumentActions = typeof actions;
export type DocumentMutations = typeof mutations;
