/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosPromise } from 'axios';
import querystring from 'querystring';
// import { I8TableColumn } from 'i8-ui';
import { OverlayAction } from '@/mixin';
import { OverlayDocument } from '@/store';

export interface AppServices {
  Document: DocumentService;
  File: FileService;
  Auth: AuthService;
  Api: ApiService;
  Config: ConfigService;
}

export interface ApiService {
  init(baseURL: string): AxiosPromise<any>;
  authTokenSet(token: string): void;
  get(url: string, config?: AxiosRequestConfig | undefined): AxiosPromise<any>;
  post(
    url: string,
    data?: any,
    config?: AxiosRequestConfig | undefined,
  ): AxiosPromise<any>;
  put(
    url: string,
    data?: any,
    config?: AxiosRequestConfig | undefined,
  ): AxiosPromise<any>;
  delete(
    url: string,
    config?: AxiosRequestConfig | undefined,
  ): AxiosPromise<any>;
  getToken(): string;
  getModuleUrl(module: string): string;
}

export type Service =
  | DocumentService
  | FileService
  | AuthService
  | ApiService
  | RenderSchemaService
  | ConfigService;

export interface DocumentService {
  getById(
    documentId: string,
    baseUrlOverride?: boolean,
    moduleId?: string,
  ): AxiosPromise<any>;
  getByType(
    query: DocumentAPIQuery,
    request: object | undefined,
    moduleUrlOverride?: boolean,
    moduleId?: string,
  ): AxiosPromise<any>;
  getEvents(
    documentId: string,
    query: EventAPIQuery,
    moduleUrlOverride?: boolean,
    moduleId?: string,
  ): AxiosPromise<any>;
}

export interface FileService {
  get(query: FileAPIQuery): AxiosPromise<any>;
  downloadById(
    fileId: string,
    accept?: string,
    moduleUrlOverride?: boolean,
    moduleUrl?: string,
  ): AxiosPromise<any>;
  createFile(file: File, documentId: string | undefined): AxiosPromise<any>;
  deleteById(fileId: string): AxiosPromise<any>;
}

export interface DocumentFileService {
  get(
    documentId: string,
    moduleUrlOverride?: boolean,
    moduleUrl?: string,
  ): AxiosPromise<any>;
  downloadByDocumentIdAndFileId(
    documentId: string,
    fileId: string,
    moduleUrlOverride?: boolean,
    moduleId?: string,
  ): AxiosPromise<any>;
  fileContentsByDocumentIdAndFileId(
    documentId: string,
    fileId: string,
    moduleUrlOverride?: boolean,
    moduleId?: string,
  ): Promise<any>;
  createFile(file: File, documentId: string | undefined): AxiosPromise<any>;
  deleteById(documentId: string, fileId: string): AxiosPromise<any>;
  fileUri(documentId: string, fileId: string): string;
}

export interface AuthService {
  getProviders(): AxiosPromise<any>;
  current(): AxiosPromise<any>;
  loginInternal(user: UserCredentials): AxiosPromise<any>;
  logout(): AxiosPromise<any>;
  passwordRecover(provider: string, data: any): AxiosPromise<any>;
  passwordReset(data: any): AxiosPromise<any>;
}

export interface RenderSchemaService {
  getDocListByDocType(documentType: string): any;
  getDocOverviewByDocType(documentType: string): any;
}

export interface ConfigService {
  getAppConfig(): AxiosPromise<any>;
  getFile(path: string): AxiosPromise<any>;
}

export interface PluginService {
  getAll(): AxiosPromise<PagedOverlayApplications>;
}

export interface PagedOverlayDocuments<T = any> {
  data: OverlayDocument<T>[];
  next?: string;
  offset?: number;
  limit?: number;
  total?: number;
}

export interface PagedOverlayApplications {
  data: OverlayApplication[];
  next?: string;
  prev?: string;
}

export interface OverlayApplication {
  id: string;
  name: string;
  slug: string;
  short_description: string;
  icon: string;
  status: string;
  plugins: OverlayApplicationPlugin[];
}

export interface OverlayApplicationPlugin {
  id: string;
  slug: string;
  name: string;
  address: string;
  color: string;
  icon: string;
  patterns: string[];
}

export interface DocumentQuery {
  apiQuery: DocumentAPIQuery;
  request: { stats: any[] };
  skipCommit?: boolean;
}
export interface DocumentEventQuery {
  documentId: string;
  apiQuery: EventAPIQuery;
}

export interface DocumentAPIQuery extends querystring.ParsedUrlQueryInput {
  type: string;
  limit: number;
  cursor?: string;
}

export interface EventAPIQuery extends querystring.ParsedUrlQueryInput {
  limit: number;
  cursor?: string;
}

export interface FileAPIQuery extends querystring.ParsedUrlQueryInput {
  attachment_document_id: string;
}

export interface UserCredentials {
  username: string;
  password: string;
}

export interface AuthProvider {
  id: string;
  is_default: boolean;
  name: string;
  for_spa_host?: string;
  allows_user_management: boolean;
  type: AuthProviderType;
}

export type AuthProviderType =
  | 'userpass'
  | 'oidc'
  | 'saml'
  | 'oauth2'
  | 'proxy';

export type ViewContext = Record<string, any>;

// document.list layout config
export interface ViewConfigLayoutDocumentList {
  title: string;
  tabTitle?: string;
  search?: boolean;
  filters: any[];
  query?: Record<string, any>;
  viewContext?: ViewContext;
  table: DocumentListTableConfig | DocumentListTableConfigSelectable;
}

// document.list table config
export const PaginationType = {
  CURSOR: 'cursor',
  BASIC: 'basic',
  ADVANCED: 'advanced',
} as const;
export type PaginationType =
  (typeof PaginationType)[keyof typeof PaginationType];

export interface DocumentListTableConfig {
  paginationType?: PaginationType;
  hover?: boolean;
  columns: any[]; // I8TableColumn[];
  actions?: DocumentListTableAction[];
  slots?: any[];
  quickViewSchema?: any;
  options?: DocumentListOptions;
}

// document.list selectable table config
export interface DocumentListTableConfigSelectable
  extends DocumentListTableConfig {
  singleRowSelect: boolean;
  rowSelectAction: OverlayAction;
}

// document.list table actions
// this is different from OverlayAction because
// it is rendered using router-link for increased accessibility
export interface DocumentListTableAction {
  label: string;
  icon: string;
  viewId: string;
}

// User configurable options for the table component within document.list
export interface DocumentListOptions {
  readonly [option: string]: any;
  listNamespace: string;
  pageSizeOptions: number[];
  pageSizeDefault: number;
  columnSelection?: boolean;
}
