










import Vue from 'vue';
const OVersion = Vue.extend({
  name: 'o-version',
  props: {
    version: {
      type: String,
    },
  },
});
export { OVersion };
export default OVersion;
