import { UserState, OverlayUser } from '@/store';
import { OverlayUserPrefs, OverlayUserPrefsNamespace } from './types';

// Get details about the currently logged in user
export const userMeGet = (state: UserState): OverlayUser => {
  return state.me;
};

// Verify that the currently logged in user has a permission
export const userHas =
  (state: UserState) =>
  (permission: string): boolean => {
    return state.me?.permissions.includes(permission);
  };

// Verify that the currently logged in user belongs to a team
export const userBelongsTo =
  (state: UserState) =>
  (teamId: string): boolean => {
    return state.me?.teams
      .map((x: { id: string; name: string }) => x.id)
      .includes(teamId);
  };

// Get user preferences by namespace
export const userPrefs =
  (state: UserState) =>
  (namespace: OverlayUserPrefsNamespace): OverlayUserPrefs => {
    return state.preferences[namespace];
  };
