import {
  DashboardViewConfig,
  DashboardDailyViewConfig,
  UserProfileViewConfig,
  DocumentListViewConfig,
  DocumentViewConfig,
  TabsViewConfig,
  DocumentOverviewViewConfig,
  DocumentTimelineViewConfig,
  DocumentFileViewConfig,
  LayoutConfig,
} from '@/store';
import { RouteConfig } from 'vue-router';
import {
  createViewHandler,
  combine,
  Overlay,
  ViewHandler,
} from '@overlay/module-sdk';
import Vue from 'vue';

// Dynamically create document router entires
export const appRoutes = (views: unknown[]): RouteConfig[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const moduleViews = Overlay.getModuleViews(Vue as any);
  /* eslint-disable @typescript-eslint/no-use-before-define */
  const handler = combine([
    dashboard,
    dashboardDaily,
    document(moduleViews),
    documentList,
    layout(moduleViews),
    tabs(moduleViews),
    userProfile,
    ...moduleViews,
  ]);
  /* eslint-enable @typescript-eslint/no-use-before-define */
  return views.map(handler.getRouteConfig);
};

const userProfile = createViewHandler<UserProfileViewConfig>({
  type: 'user.profile',
  component: () =>
    import(/* webpackChunkName: "user" */ `@/screen/user/user.profile.vue`),
});

const dashboard = createViewHandler<DashboardViewConfig>({
  type: 'dashboard',
  component: () =>
    import(
      /* webpackChunkName: "dashboard" */ `@/screen/dashboard/dashboard.vue`
    ),
});

const dashboardDaily = createViewHandler<DashboardDailyViewConfig>({
  type: 'dashboard.daily',
  component: () =>
    import(
      /* webpackChunkName: "dashboard" */ `@/screen/dashboard/daily/dashboard.daily.vue`
    ),
});

const documentList = createViewHandler<DocumentListViewConfig>({
  type: 'document.list',
  component: () =>
    import(
      /* webpackChunkName: "document" */ `@/screen/document/list/document.list.vue`
    ),
  extendRoute: (view, route) => {
    route.meta.documentType = view.documentType;
  },
});

const documentOverview = createViewHandler<DocumentOverviewViewConfig>({
  type: 'document.overview',
  component: () =>
    import(
      /* webpackChunkName: "document" */ `@/screen/document/overview/document.overview.vue`
    ),
});

const documentTimeline = createViewHandler<DocumentTimelineViewConfig>({
  type: 'document.timeline',
  component: () =>
    import(
      /* webpackChunkName: "document" */ `@/screen/document/timeline/document.timeline.vue`
    ),
});

const documentFile = createViewHandler<DocumentFileViewConfig>({
  type: 'document.file',
  component: () =>
    import(
      /* webpackChunkName: "document" */ `@/screen/document/file/document.file.vue`
    ),
});

const document = (children: ViewHandler[]) =>
  createViewHandler<DocumentViewConfig>({
    type: 'document',
    component: () =>
      import(
        /* webpackChunkName: "document" */ `@/screen/document/document.vue`
      ),
    extendRoute: (view, route) => {
      route.redirect = {
        name: view.defaultChildViewId,
      };
    },
    children: () => [
      documentOverview,
      documentTimeline,
      documentFile,
      ...children,
    ],
  });

const layout = (children: ViewHandler[]) =>
  createViewHandler<LayoutConfig>({
    type: 'overlay.layout',
    component: () =>
      import(
        /* webpackChunkName: "overlay" */ `@/screen/layout/overlay.layout.vue`
      ),
    extendRoute: (view, route) => {
      route.redirect = {
        name: view.defaultChildViewId,
      };
    },
    children: () => [...children],
  });

const tabs = (children: ViewHandler[]) =>
  createViewHandler<TabsViewConfig>({
    type: 'overlay.tabs',
    component: () =>
      import(
        /* webpackChunkName: "overlay" */ `@/screen/tabs/overlay.tabs.vue`
      ),
    extendRoute: (view, route) => {
      route.redirect = {
        name: view.defaultChildViewId,
      };
    },
    children: () => [documentList, ...children],
  });
