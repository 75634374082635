/* eslint-disable @typescript-eslint/no-use-before-define */
import ReconnectingWebsocket from 'reconnecting-websocket';
import { Store } from 'vuex';

import {
  PubSubSubscription,
  DocumentEvent,
  OverlayDocument,
} from '@/store';

const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
let pinger: NodeJS.Timeout | undefined;
const pingTime = 10000; // 10 seconds

interface MistMessage {
  command: string;
  tags?: string[];
  data?: string;
}

export const PubSubPlugin = (store: Store<unknown>): void => {
  // Locally declare
  let socket: ReconnectingWebsocket | undefined;
  store.subscribe((mutation) => {
    switch (mutation.type) {
      case 'pubsub/subscriptionRemove':
        if (!socket || socket.readyState !== socket.OPEN) {
          // the socket isn't ready
          return;
        }

        socket.send(
          JSON.stringify({
            command: 'unsubscribe',
            tags: mutation.payload.subscription.tags,
          }),
        );
        break;

      case 'pubsub/subscriptionAdd':
        if (!socket || socket.readyState !== socket.OPEN) {
          return;
        }

        socket.send(
          JSON.stringify({
            command: 'subscribe',
            tags: mutation.payload.subscription.tags,
          }),
        );

        break;

      case 'config/uiConfigSet':
        if (mutation.payload.pubsub_disabled) {
          console.log("websockets disabled")
          return
        }
        // eslint-disable-next-line no-case-declarations
        let address = `${protocol}://${window.location.host}/api/ui/pubsub`;
        if (mutation.payload.pubsub_url != '' && mutation.payload.pubsub_url) {
          address = mutation.payload.pubsub_url;
        }

        socket = new ReconnectingWebsocket(address);

        socket.onclose = () => {
          store.commit(`pubsub/statusChange`, 'close');
        };

        socket.onopen = () => {
          store.commit(`pubsub/statusChange`, 'open');

          const token = store.getters['auth/session'].token;
          if (socket) {
            socket.send(JSON.stringify({token}));
          }

          const channels = store.getters['pubsub/subscriptionsList'];
          channels.forEach((c: PubSubSubscription) => {
            try {
              if (!socket || socket.readyState !== socket.OPEN) {
                return;
              }

              socket.send(
                JSON.stringify({
                  command: 'subscribe',
                  tags: c.tags,
                }),
              );
            } catch (exp) {
              console.warn('Could not subscribe after a connection');
              console.warn(exp);
            }
          });
        };
        // new message from the web socket
        socket.onmessage = (msg: MessageEvent) => {
          try {
            // web socket message
            const message = JSON.parse(msg.data) as MistMessage;

            if (message.data === 'pong') {
              return;
            }

            const messageData = message.data
              ? JSON.parse(message.data)
              : undefined;

            // Find the type of message based on the tags
            const messageType = getMessageTypeFromTags(message.tags);

            // message handlers
            const handlers = {
              event: handleEvent,
              document: handleDocument,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any;

            if (Object.keys(handlers).includes(messageType)) {
              // pass the message to the appropriate handler
              handlers[messageType](messageData, store);
            } else {
              console.warn(`No handler for ${messageType} message`, message);
            }
          } catch (exp) {
            console.warn('Error in PubSubPlugin', exp);
          }
        };

        if (pinger) {
          clearInterval(pinger);
        }
        pinger = setInterval(() => sendPing(socket), pingTime);

        break;
      case 'auth/loggedOut':
        if (socket) {
          socket.close();
          socket = undefined;
        }
        break;
    }
  });
};

// some environments require ws chatter to keep the socket alive
function sendPing(socket: ReconnectingWebsocket | undefined) {
  if (!socket || socket.readyState !== socket.OPEN) {
    // the socket isn't ready
    return;
  }

  socket.send(JSON.stringify({ command: 'ping' }));
}

function handleEvent(event: DocumentEvent, store: Store<unknown>): void {
  store.dispatch('document/documentEventsAdd', {
    documentId: event.document_id,
    events: [event],
  });
}

function handleDocument(
  document: OverlayDocument,
  store: Store<unknown>,
): void {
  store.dispatch('document/documentByIdUpdate', document);
}

type MessageMap = {
  [key: string]: string
}

// getMessageTypeFromTags
function getMessageTypeFromTags(tags: string[] = []) {
  const messageTypes = ['block', 'document_event', 'document', 'health'];
  const messageMap = {
    "document_event" : "event",
    "document" : "document",
    "block" : "block",
    "health" : "health"
  } as MessageMap

  const messageType: string[] = []

  tags.forEach(tag => {
    const mt = messageTypes.filter(type => tag.includes(type))
    messageType.push(...mt)
  })

  if (messageType.length > 1) {
    console.warn(
      'Multiple message type tags found in web socket message',
      messageType,
    );
  }

  return messageMap[messageType[0]]

}
