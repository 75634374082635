















import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import { I8PageNav } from 'i8-ui';

import { OAppPage, OPageConfig } from '@/mixin';

export default Vue.extend({
  mixins: [OAppPage, OPageConfig],

  components: {
    I8PageNav,
  },

  computed: {
    ...mapGetters('config', [
      'documentDetailViewsGet',
      'viewConfig',
      'viewSchemaGet',
    ]),

    /**
     * Page title.
     *
     *  @return { string }
     */
    pageTitle(): string {
      if (this.config) {
        return this.config.title;
      }

      return '';
    },

    /**
     * Configuration object for this view.
     *
     * This is loaded from a JSON file referenced in config.json.
     *
     * @return { Object }
     */
    /* eslint-disable @typescript-eslint/no-explicit-any */
    config(): any {
      const vm = this as any;
      const config = vm.viewConfig(this.$route.meta.parentId);
      return vm.preprocessConfig(config);
    },

    /**
     * Configured tabs
     *
     * @return { Object[] }
     */
    navItems(): any[] {
      const vm = this as any;

      if (!this.config || !this.config.views) {
        return [];
      }

      return this.config.views
        .filter((v: any) => v.active)
        .map((v: any) => {
          const config = vm.viewSchemaGet(v.id);
          const title = config ? config.tabTitle : v.id;
          return {
            id: v.id,
            route: {
              path: v.path,
            },
            title,
          };
        });
    },
  },
  /* eslint-enable @typescript-eslint/no-explicit-any */

  methods: {
    ...mapActions('config', ['viewSchemaLoad']),

    /**
     * Load all data needed for this view.
     *
     * Includes:
     *   - Config for each configured tab
     *
     * @return { Promise<void> }
     */
    async loadData(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      try {
        vm.loadingStart();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.config.views.forEach(async (view: any) => {
          if (!vm.viewSchemaGet(view.id)) {
            await vm.viewSchemaLoad({
              viewId: view.id,
              parentViewId: this.$route.meta.parentId,
            });
          }
        });

        // load document list template
      } catch (error) {
        vm.loadingError(error);
      } finally {
        vm.loadingComplete();
      }
    },
  },

  watch: {
    /**
     * When out config changes, load all data for this view.
     *
     * This is done in a watcher instead of a lifecycle hook to work around
     * cases where vue re-uses the component instance.
     */
    config: {
      handler(newConfig) {
        if (!newConfig || !newConfig.views) {
          return;
        }

        this.loadData();
      },
      immediate: true,
    },
  },
});
