import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { PluginState, OPluginsById, IntentsByPluginId } from '@/store';

export const plugin: Module<PluginState, unknown> = {
  namespaced: true,
  state: {
    context: {
      type: undefined,
    },
    plugin: {} as OPluginsById,
    intentQueue: {} as IntentsByPluginId,
  },
  actions,
  mutations,
  getters,
};

export * from './types';
export type PluginGetters = typeof getters;
export type PluginActions = typeof actions;
export type PluginMutations = typeof mutations;
