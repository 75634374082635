




















































import Vue, { PropType } from 'vue';

import { I8Icon } from 'i8-ui';
import { OPluginState } from './o-plugin-state';
import { Overlay } from 'overlay-plugin-sdk';

// Icon library
import { faFrown } from '@fortawesome/pro-light-svg-icons/faFrown';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faFrown);

import { mapGetters, MutationPayload } from 'vuex';
import { OPluginIntent, OPlugin as StorePlugin, OPluginContext } from '@/store';

export const OPlugin = Vue.extend({
  name: 'o-plugin',

  mixins: [OPluginState],

  components: {
    I8Icon,
  },

  props: {
    plugin: {
      type: Object as PropType<StorePlugin>,
      required: true,
    },
    context: {
      type: Object as PropType<OPluginContext | null>,
      default: null,
    },
  },

  data() {
    return {
      pluginApi: null as Overlay.PluginApi | null,
      retryWaitTime: 30000, // 30s
    };
  },

  mounted() {
    this.initPlugin();
  },

  beforeDestroy() {
    if (this.pluginApi) {
      this.pluginApi.destroy();
    }
  },

  computed: {
    ...mapGetters('auth', ['session']),
  },

  methods: {
    ...mapGetters('plugin', ['intentDequeue']),

    /**
     * Initialise a plugin.
     *
     * Uses the Overlay+ handshake process in `plugin-sdk`.
     */
    async initPlugin() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      vm.pluginConnecting();

      // wait for the iframe to be created in the dom
      await new Promise((r) => setTimeout(r, 100));

      try {
        this.pluginApi = await Overlay.connectToPlugin({
          container: this.$refs.pluginContainer as HTMLIFrameElement,
          url: this.plugin.href,
        });
        this.pluginChannel(this.pluginApi);
        // TODO: refresh the page and redirect to the current page once backend ready
        // reload page to redirect to login screen
        // iframe will post unauthorised response data to parent
        // listen to unauthorised messages from iframe
        window.addEventListener('message', async (event) => {
          const response = event.data;
          if (response && response.status === 401) {
            window.location.href = '/logout';
          }
        });
      } catch (err) {
        vm.pluginError(err);
        this.$emit('error', this.plugin);
        this.retryInit();
      }
    },

    /**
     * Retry to connecto to the plugin after a set amount of time
     */
    retryInit() {
      const retry = setTimeout(() => {
        clearTimeout(retry);
        this.initPlugin();
      }, this.retryWaitTime);
    },

    /**
     * Plugin communication channel.
     *
     * This is where all communication between overlay and the plugin is done.
     *
     * Overlay calls methods expoesed by the plugin.
     * Plugins emit events which are handled by Overlay.
     */
    async pluginChannel(plugin: Overlay.PluginApi) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      vm.pluginConnected();

      // send over the user session
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await plugin.dispatch('userSessionSet', (this as any).session);
      } catch (error) {
        console.error(`${this.plugin.name} could not receive the user session`);
        return vm.pluginError(error);
      }

      // if we're here, we've successfully connected to the plugin
      this.$emit('connected', this.plugin);

      // when the context changes, notify the plugin
      this.$watch(
        'context',
        async () => {
          try {
            // this is the context of what the user is looking at.
            // the two avaialble context types are:
            //   - `document`
            //   - `document.list`
            if (this.context && this.context.type) {
              await plugin.dispatch('setContext', this.context);
            }
          } catch (error) {
            console.error(
              `${this.plugin.name} could not receive a context update ${this.context}`,
            );
            return vm.pluginError(error);
          }
        },
        {
          immediate: true,
        },
      );

      // Intents are object that can be triggered from anywhere in overlay.
      // Each plugin maintains an intent queue in vuex which is monitored here.
      //
      // When an intent is found that is intended for this plugin and can be actioned,
      // it becomes an action and is sent to the plugin to be handled.
      //
      // Plugins must declare the actions they can handle in their manifest.
      this.$store.subscribe(async (mutation: MutationPayload) => {
        // the only way to monitor the queue is to check every vuex mutation to
        // see if it's adding a new intent :'(
        if (mutation.type !== 'plugin/intentEnqueue') {
          // not a new intent
          return;
        }

        if (mutation.payload.pluginId !== this.plugin.id) {
          // the intent is not for this plugin
          return;
        }

        // we have an intent for this plugin so let's grab it from the queue
        const intent: OPluginIntent = vm.intentDequeue()(this.plugin.id);

        if (intent) {
          // only explidit intents can trigger a focus switch
          if (intent.type === 'explicit' && intent.showPlugin) {
            // open the side bar and switch focus to this plugin
            this.$emit('show', this.plugin.id);

            // TODO: find a more elegant way to do this.
            // the scenario is that we just want to open the sidebar
            // and display this plugin
            if (intent.action === 'show') {
              return mutation.payload.resolve();
            }
          }

          try {
            // send the action to the plugin
            const pluginResponse = await plugin.dispatch(
              intent.action,
              intent.payload,
            );

            // TODO: don't call this method from the mutation payload, queue it up instead
            // resolve the inital promise with the payload returned from the plugin
            mutation.payload.resolve(pluginResponse);
          } catch (error) {
            console.error(
              `${this.plugin.name} was unable to do an action ${intent.action}`,
            );

            // TODO: don't call this method from the mutation payload, queue it up instead
            // ensure that the caller know's what's up
            mutation.payload.reject(error);
          }
        }
      });
    },
  },
});

export default OPlugin;
