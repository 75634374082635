import { PubSubState, PubSubSubscription } from '@/store';
import { toConsistentHash } from './helpers';

import isEmpty from 'lodash/isEmpty';
import Vue from 'vue';

export const subscriptionAdd = (
  state: PubSubState,
  { subscription }: { subscription: PubSubSubscription },
): void => {
  if (isEmpty(subscription.hash)) {
    subscription.hash = toConsistentHash(subscription.tags);
  }

  Vue.set(state.byHash, subscription.hash, subscription);
};

export const subscriptionRemove = (
  state: PubSubState,
  { subscription }: { subscription: PubSubSubscription },
): void => {
  if (isEmpty(subscription.hash)) {
    subscription.hash = toConsistentHash(subscription.tags);
  }

  Vue.delete(state.byHash, subscription.hash);
};

export const statusChange = (
  state: PubSubState,
  { status }: { status: string },
): void => {
  Vue.set(state, 'status', status);
};
