import { Api, BASE_URL } from '@/service/api.service';
import { FileService, DocumentFileService } from '@/service/types';
import { AxiosRequestConfig } from 'axios';
import { extractFileName } from 'i8-ui';

const resource = '/files';

const overlayDocumentFilesResource = (documentId: string) =>
  `/documents/${documentId}/files`;

export const File = {
  downloadById(fileId: string, accept?: string) {
    return Api.get(`${resource}/${fileId}/download`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: accept || '*/*',
      },
    });
  },
} as FileService;

export const DocumentFile = {
  // Get all available files
  get(documentId: string, moduleUrlOverride?: boolean, moduleId?: string) {
    if (moduleUrlOverride && moduleId) {
      const moduleUrl = Api.getModuleUrl(moduleId);
      return Api.get(`${overlayDocumentFilesResource(documentId)}`, {
        baseURL: `${moduleUrl}${BASE_URL}`,
        headers: {
          Authorization: `Bearer ${Api.getToken()}`,
        },
      });
    }
    return Api.get(overlayDocumentFilesResource(documentId));
  },

  downloadByDocumentIdAndFileId(
    documentId: string,
    fileId: string,
    moduleUrlOverride?: boolean,
    moduleId?: string,
  ) {
    if (moduleUrlOverride && moduleId) {
      const moduleUrl = Api.getModuleUrl(moduleId);
      return Api.get(DocumentFile.fileUri(documentId, fileId), {
        baseURL: `${moduleUrl}${BASE_URL}`,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${Api.getToken()}`,
          Accept: '*/*',
        },
      });
    }
    return Api.get(DocumentFile.fileUri(documentId, fileId), {
      responseType: 'arraybuffer',
      headers: {
        Accept: '*/*',
      },
    });
  },

  async fileContentsByDocumentIdAndFileId(
    documentId: string,
    fileId: string,
    moduleUrlOverride?: boolean,
    moduleId?: string,
  ) {
    const fileUri = DocumentFile.fileUri(documentId, fileId);
    const fileRequestConfig = {
      responseType: 'arraybuffer',
      headers: {
        Accept: '*/*',
      },
    } as AxiosRequestConfig;

    // Module based APIs require an extended configuration
    if (moduleUrlOverride && moduleId) {
      const moduleUrl = Api.getModuleUrl(moduleId);
      fileRequestConfig.baseURL = `${moduleUrl}${BASE_URL}`;
      fileRequestConfig.headers = {
        ...fileRequestConfig.headers,
        Authorization: `Bearer ${Api.getToken()}`,
      };
    }

    const resp = await Api.get(fileUri, fileRequestConfig);
    const filename = extractFileName(resp);
    const mime = resp.headers['content-type'];
    return { data: resp.data, filename, mime };
  },

  fileUri(documentId: string, fileId: string) {
    return `${overlayDocumentFilesResource(documentId)}/${fileId}/download`;
  },

  // Upload a new file
  createFile(file: File, documentId: string | undefined) {
    const formData = new FormData();
    formData.append('file', file);

    if (!documentId) {
      throw new Error('documentId must be supplied');
    }

    return Api.post(
      `${overlayDocumentFilesResource(documentId)}/content`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },

  // Delete a single file by file id
  deleteById(documentId: string, fileId: string) {
    return Api.delete(`${overlayDocumentFilesResource(documentId)}/${fileId}`);
  },
} as DocumentFileService;
