










import Vue from 'vue';
import { I8Icon } from 'i8-ui';

// Icon library
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';

// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faCheck);

const OSavedNotification = Vue.extend({
  name: 'o-saved-notification',
  components: {
    I8Icon,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      hide: true,
      string: {
        saved: 'Saved',
      },
    };
  },

  watch: {
    value(newVal: boolean) {
      if (!newVal) {
        return;
      }

      setTimeout(() => {
        this.$emit('input', false);
      }, 2000);
    },
  },
});

export default OSavedNotification;
export { OSavedNotification };
