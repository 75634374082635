import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faTimesHexagon } from '@fortawesome/pro-light-svg-icons/faTimesHexagon';

import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faInfoCircle,
  faQuestionCircle,
  faExclamationTriangle,
  faTimesHexagon,
);
