



















import Vue from 'vue';
import { mapActions } from 'vuex';
import { OPluginIntent } from '@/store';
import { I8Icon } from 'i8-ui';
import { BTN_TYPE, BTN_PRIMARY } from './consts';

// Icon library
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSpinner);

export const OButtonIntent = Vue.extend({
  name: 'o-button-intent',

  components: {
    I8Icon,
  },

  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: BTN_PRIMARY,
      validator: (t: string) => BTN_TYPE.includes(t),
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    intent: {
      type: Object as () => OPluginIntent,
      required: true,
    },
  },

  data() {
    return {
      page: {
        state: 0,
      },
      state: {
        loaded: 0,
        loading: 1,
      },
    };
  },

  computed: {
    isLoading(): boolean {
      return this.page.state === this.state.loading;
    },
  },

  methods: {
    ...mapActions('plugin', ['intentEnqueue']),

    async sendIntent(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      vm.stateLoading();
      await vm.intentEnqueue(vm.intent);
      vm.stateLoaded();
    },

    stateLoading(): void {
      this.page.state = this.state.loading;
    },

    stateLoaded(): void {
      this.page.state = this.state.loaded;
    },
  },
});
export default OButtonIntent;
