

































































import Vue from 'vue';

const OOverlayLogo = Vue.extend({
  name: 'o-overlay-logo',

  props: {
    isExpanded: { type: Boolean, default: false },
    isHighlighted: { type: Boolean, default: false },
  },

  data() {
    return {
      localHighlight: false,
    };
  },

  methods: {
    click() {
      this.$emit('click');
    },
  },

  watch: {
    isHighlighted(newValue) {
      this.localHighlight = newValue;
    },
  },
});

export { OOverlayLogo };
export default OOverlayLogo;
