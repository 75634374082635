




import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { I8Toast } from 'i8-ui';
import { ToastObject } from './types';

import { OverlayState, ONotification } from '@/store';

const OToast = Vue.extend({
  name: 'o-toast',

  components: {
    I8Toast,
  },

  data() {
    return {
      defaultOptions: {
        position: 'bottom-left',
        theme: 'i8-toast',
        action: [
          {
            text: 'Dismiss',
            onClick: (e: Event, toast: ToastObject) => {
              toast.goAway(0);
            },
          },
        ],
        duration: 5000,
      },
    };
  },

  computed: {
    ...mapState<OverlayState>('overlay', ['notifications']),
    ...mapGetters('overlay', ['nextNotification']),
  },

  methods: {
    ...mapActions('overlay', ['notificationDequeue']),

    async showNotification(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      // get the next notification from the queue
      const notification: ONotification = vm.nextNotification;

      // remove the item from the queue
      await vm.notificationDequeue();

      // show notification
      vm.$refs.toast.show(notification.message, notification);
    },
  },

  watch: {
    notifications(newVal: ONotification[]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      if (newVal && newVal.length > 0) {
        vm.showNotification();
      }
    },
  },
});

export { OToast };
export default OToast;
