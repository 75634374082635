






















/* eslint-disable @typescript-eslint/no-explicit-any */
// Vue
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

// Components
import { OFileManager } from '@/component';

import { OverlayFile } from '@/store';

import { TABLE_CONFIG_FILE } from './default';
import { OAppPage, OActions } from '@/mixin';
import { BlobDetails } from 'i8-ui';

export default Vue.extend({
  name: 'document.file',

  mixins: [OAppPage, OActions],

  components: {
    OFileManager,
  },

  data() {
    return {
      isDownloading: {},
    };
  },

  computed: {
    ...mapGetters('file', ['allFiles']),
    ...mapGetters('config', ['viewSchemaGet']),

    /**
     * Document ID for the docuemnt being viewed.
     *
     * This is loaded from a parameter in the URL.
     *
     * @return { string }
     */
    documentId(): string {
      return this.$route.params.id;
    },

    /**
     * Configuration object for this view.
     *
     * This is loaded from a JSON file referenced in config.json.
     *
     * @return { Object }
     */
    screenConfig(): any {
      const vm = this as any;
      return vm.viewSchemaGet(this.$route.name);
    },

    /**
     * Is the user permitted to upload new files?
     *
     * @return { boolean }
     */
    allowFileUpload(): boolean {
      const vm = this as any;
      return !!vm.screenConfig && !!vm.screenConfig.fileUpload;
    },

    /**
     *  File attributes to show in the table.
     *
     *  @return { Object[] } i8-table columns
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    fileAttributes(): object[] {
      const vm = this as any;
      const config = vm.screenConfig;

      if (!config || !config.table) {
        return TABLE_CONFIG_FILE;
      }

      return config.table.columns;
    },
  },

  methods: {
    ...mapActions('config', ['viewSchemaLoad']),
    ...mapActions('file', [
      'fileCreate',
      'fileListLoad',
      'fileByIdContent',
      'fileByIdDownload',
      'fileByIdDelete',
    ]),

    /**
     * Has a particular been marked as deleted?
     *
     * @return { boolean }
     */
    isDeleted(file: OverlayFile): boolean {
      return file.state.status === 'deleted';
    },

    /**
     * Load all data needed for this view.
     *
     * Includes:
     *   - list of files to display
     *   - view configuration
     *
     * @return { Promise<void> }
     */
    async loadData(): Promise<void> {
      const vm = this as any;
      vm.loadingStart();

      try {
        if (!vm.screenConfig) {
          // load document list template
          await vm.viewSchemaLoad({
            viewId: this.$route.name,
            parentViewId: this.$route.meta.parentId,
          });
        }

        await vm.fileListLoad({
          documentId: this.documentId,
          moduleUrlOverride: vm.$parent.moduleUrlOverride || undefined,
          moduleId: vm.$parent.moduleId || undefined,
        });
      } catch (error) {
        vm.loadingError(error);
      } finally {
        vm.loadingComplete();
      }
    },

    /**
     * Upload a new file.
     *
     * @return { void }
     */
    uploadFiles(files: File[]): void {
      const vm = this as any;
      for (const file of files) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const uploaded = new Date();
        // upload file
        vm.fileCreate({ file, documentId: this.documentId });
      }
    },

    /**
     * Mark a file as deleted.
     *
     * @return { Promise<void> }
     */
    async deleteFile(file: OverlayFile): Promise<void> {
      const vm = this as any;
      await vm.fileByIdDelete({ fileId: file.id, documentId: this.documentId });
    },

    /**
     * Download a file.
     *
     * @return { Promise<void> }
     */
    async downloadFile(file: OverlayFile): Promise<void> {
      const vm = this as any;
      try {
        this.$set(this.isDownloading, file.id, true);
        await vm.fileByIdDownload({
          documentId: this.documentId,
          fileId: file.id,
          accept: file.state.content.type,
          moduleUrlOverride: vm.$parent.moduleUrlOverride || undefined,
          moduleId: vm.$parent.moduleId || undefined,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$set(this.isDownloading, file.id, false);
      }
    },

    async blobUrl(file: OverlayFile): Promise<BlobDetails | null> {
      const vm = this as any;
      try {
        const { data, mime, filename } = await vm.fileByIdContent({
          documentId: this.documentId,
          fileId: file.id,
          accept: file.state.content.type,
          moduleUrlOverride: vm.$parent.moduleUrlOverride || undefined,
          moduleId: vm.$parent.moduleId || undefined,
        });
        const blob = new Blob([data], {
          type: mime || 'application/octet-stream',
        });
        const blobURL = URL.createObjectURL(blob);
        return { blobURL, mime, filename };
      } catch (error) {
        console.log(error);
      }
      return null;
    },
  },

  watch: {
    /**
     * When the document ID changes, load all data for this view.
     *
     * This is done in a watcher instead of a lifecycle hook to work around
     * cases where vue re-uses the component instance.
     */
    documentId: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handler(newVal: string, oldVal: string) {
        this.loadData();
      },
      immediate: true,
    },
  },
});
