














import Vue from 'vue';

const OFooter = Vue.extend({
  name: 'o-footer',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
});

export { OFooter };
export default OFooter;
