import { PubSubContext, PubSubSubscription } from '@/store';
import { toConsistentHash } from './helpers';

export const subscribe = async (
  ctx: PubSubContext,
  subscription: PubSubSubscription,
): Promise<void> => {
  // Ensure ID is not some sort of undefined value
  if (subscription.tags.length > 0) {
    if (!ctx.state.byHash[toConsistentHash(subscription.tags)]) {
      ctx.commit('subscriptionAdd', { subscription });
    }
  }
};

export const unsubscribe = async (
  ctx: PubSubContext,
  subscription: PubSubSubscription,
): Promise<void> => {
  // Ensure ID is not some sort of undefined value
  if (subscription.tags.length > 0) {
    ctx.commit('subscriptionRemove', { subscription });
  }
};
