import { AuthState } from '@/store';
import { AuthProvider } from '@/service';
import { AuthSession } from './types';

// Is the user authenticated?
export const isAuthenticated = (state: AuthState): boolean => {
  return state.isAuthenticated;
};

export const providers = (state: AuthState): AuthProvider[] => {
  return state.providers;
};

export const session = (state: AuthState): AuthSession => {
  return state.session;
};
