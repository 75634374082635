import { ConfigContext, OverlayConfig } from '@/store';
import { Config } from '@/service';
import { Overlay } from '@overlay/module-sdk';
import Vue from 'vue';

// Load the application config and install registered UI modules
export const appConfigLoad = async (ctx: ConfigContext): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const appResponse = await Config.getAppConfig(ctx.getters.name!);
  const config = appResponse.data as OverlayConfig;

  ctx.commit('appMetaConfigSet', config.applicationMeta);
  ctx.commit('appNavConfigSet', config.globalNav);
  ctx.commit('appViewConfigSet', config.views);
  ctx.commit('eventConfigListSet', config.eventTypes);
  ctx.commit('globalRenderContextSet', config.globalContext);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  for (const module of ctx.getters.modules!) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await Overlay.installModule(Vue as any, module);
  }
};

// Load the UI config
export const uiConfigLoad = async (ctx: ConfigContext): Promise<void> => {
  const uiResponse = await Config.getUIConfig();
  ctx.commit('uiConfigSet', uiResponse.data);
};

export const themeSet = async (
  ctx: ConfigContext,
  themeName: string,
): Promise<void> => {
  ctx.commit('themeSet', themeName);
};

// Load a view schema
export const viewSchemaLoad = async (
  ctx: ConfigContext,
  { viewId, parentViewId }: { viewId: string; parentViewId: string },
): Promise<void> => {
  const name = ctx.getters.name;
  if (!name) {
    console.warn(`Config name not found`);
    return;
  }

  const view = ctx.getters.viewConfig(viewId, parentViewId);
  if (!view) {
    console.warn('View config not found for', viewId);
    return;
  }

  if (!view.layoutPath) {
    console.warn('View schema path not found for', view.id);
  } else {
    const response = await Config.getAppConfigFile(name, view.layoutPath);
    const schema = response.data;
    ctx.commit('viewSchemaSet', { viewId: view.id, schema });
  }
};

// Load an event type body schema
export const eventBodySchemaLoad = async (
  ctx: ConfigContext,
  { eventType }: { eventType: string },
): Promise<void> => {
  const name = ctx.getters.name;
  if (!name) {
    console.warn(`Config name not found`);
    return;
  }

  const config = ctx.getters.eventConfigGet(eventType);
  if (!config) {
    console.warn(`Event config not found: ${eventType}`);
    return;
  }

  if (config.body && config.body.layoutPath) {
    const response = await Config.getAppConfigFile(
      name,
      config.body.layoutPath,
    );
    const schema = response.data;
    ctx.commit('eventBodySchemaSet', { eventType, schema });
  }
};

// Load an event type footer schema
export const eventFooterSchemaLoad = async (
  ctx: ConfigContext,
  { eventType }: { eventType: string },
): Promise<void> => {
  const name = ctx.getters.name;
  if (!name) {
    console.warn(`Config name not found`);
    return;
  }

  const config = ctx.getters.eventConfigGet(eventType);

  if (!config) {
    console.warn(`Event config not found: ${eventType}`);
    return;
  }

  if (config.footer && config.footer.layoutPath) {
    const response = await Config.getAppConfigFile(
      name,
      config.footer.layoutPath,
    );
    const schema = response.data;
    ctx.commit('eventFooterSchemaSet', { eventType, schema });
  }
};
