import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { AuthState } from '@/store';

export * from './types';

export const auth: Module<AuthState, unknown> = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    providers: [],
    claims: {},
    session: {
      aud: '',
      email: '',
      exp: 0,
      groups: [],
      iat: 0,
      iss: '',
      jti: '',
      provider: '',
      provider_type: '',
      sub: '',
      token: '',
      user: '',
      username: '',
    },
  },
  actions,
  mutations,
  getters,
};

export type AuthGetters = typeof getters;
export type AuthActions = typeof actions;
export type AuthMutations = typeof mutations;
