/* eslint-disable @typescript-eslint/ban-types */
import { Api, BASE_URL } from '@/service/api.service';
import {
  DocumentService,
  DocumentAPIQuery,
  PagedOverlayDocuments,
  EventAPIQuery,
} from '@/service/types';
import querystring from 'querystring';
import isEmpty from 'lodash/isEmpty';
import { AxiosPromise } from 'axios';

const resource = '/documents';
const overlayProxy = '/overlay';

export const Document = {
  // Get a single document by id
  getById(documentId: string, moduleUrlOverride?: boolean, moduleId?: string) {
    if (moduleUrlOverride && moduleId) {
      const moduleUrl = Api.getModuleUrl(moduleId);

      return Api.get(`${resource}/${documentId}`, {
        baseURL: `${moduleUrl}${BASE_URL}`,
        headers: {
          Authorization: `Bearer ${Api.getToken()}`,
        },
      });
    }

    return Api.get(`${resource}/${documentId}`);
  },

  // Get a list of documents by type
  getByType(
    query: DocumentAPIQuery,
    request: object,
    moduleUrlOverride?: boolean,
    moduleId?: string,
  ): AxiosPromise<PagedOverlayDocuments> {
    if (moduleUrlOverride && moduleId) {
      const moduleUrl = Api.getModuleUrl(moduleId);
      if (!isEmpty(request)) {
      return Api.post(`${resource}?${querystring.encode(query)}`, request, {
        baseURL: `${moduleUrl}${BASE_URL}`,
        headers: {
          Authorization: `Bearer ${Api.getToken()}`,
        },
      });
      }

      return Api.get(`${resource}?${querystring.encode(query)}`, {
        baseURL: `${moduleUrl}${BASE_URL}`,
        headers: {
          Authorization: `Bearer ${Api.getToken()}`,
        },
      });
    }

    if (isEmpty(request)) {
      return Api.get(`${resource}?${querystring.encode(query)}`);
    } else {
      return Api.post(`${resource}?${querystring.encode(query)}`, request);
    }
  },

  // Get a single document by id
  getEvents(
    documentId: string, 
    query: EventAPIQuery,
    moduleUrlOverride?: boolean,
    moduleId?: string,
    ) {
    if (moduleUrlOverride && moduleId) {
      const moduleUrl = Api.getModuleUrl(moduleId);

      return Api.get(`${resource}/${documentId}/events?${querystring.encode(query)}`, {
        baseURL: `${moduleUrl}${BASE_URL}`,
        headers: {
          Authorization: `Bearer ${Api.getToken()}`,
        },
      });
    }
    return Api.get(
      `${resource}/${documentId}/events?${querystring.encode(query)}`,
    );
  },
} as DocumentService;
