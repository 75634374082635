import Vue from 'vue';
import { mapGetters } from 'vuex';
import { I8PageHeader, I8PageTitle } from 'i8-ui';

import { OPageStatus } from './o-page-status';

const OAppPage = Vue.extend({
  mixins: [OPageStatus],

  components: {
    I8PageHeader,
    I8PageTitle,
  },

  props: {
    inTab: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },

  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
  },

  created() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'login' });
    }
  },
});

export { OAppPage };
export default OAppPage;
