/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api } from '@/service/api.service';
import { AxiosPromise, AxiosResponse } from 'axios';
import isString from 'lodash/isString';
import Hjson from 'hjson';
import path from 'path';

export const Config = {
  getUIConfig(): AxiosPromise<any> {
    return Api.get('/ui/config');
  },
  getAppConfig(slug: string): AxiosPromise<any> {
    return Api.get(`${window.location.origin}/data/${slug}/config.json`);
  },

  async getAppConfigFile(
    slug: string,
    filePath: string,
  ): Promise<AxiosResponse<any>> {
    const response = await Api.get(
      `${window.location.origin}/data/${slug}/${filePath}`,
    );

    if (path.extname(filePath) === '.hjson' && isString(response.data)) {
      response.data = Hjson.parse(response.data);
    }

    return response;
  },
};
