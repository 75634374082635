



















/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue';
import { I8Icon } from 'i8-ui';
import { BTN_TYPE, BTN_PRIMARY } from './consts';
/* eslint-enable @typescript-eslint/no-unused-vars */

// Icon library
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
import { OActions, OverlayAction } from '@/mixin';
library.add(faSpinner);

export const OButtonAction = Vue.extend({
  name: 'o-button-action',

  mixins: [OActions],

  components: {
    I8Icon,
  },

  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: BTN_PRIMARY,
      validator: (t: string) => BTN_TYPE.includes(t),
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Object as () => OverlayAction,
      required: true,
    },
  },

  data() {
    return {
      page: {
        state: 0,
      },
      state: {
        loaded: 0,
        loading: 1,
      },
    };
  },

  computed: {
    isLoading(): boolean {
      return this.page.state === this.state.loading;
    },
  },

  methods: {
    async sendAction(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      vm.stateLoading();
      try {
        await vm.doAction(vm.action);
      } catch (error) {
        console.error(error);
      } finally {
        vm.stateLoaded();
      }
    },

    stateLoading(): void {
      this.page.state = this.state.loading;
    },

    stateLoaded(): void {
      this.page.state = this.state.loaded;
    },
  },
});
export default OButtonAction;
