





























import Vue from 'vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { mapActions, mapGetters } from 'vuex';

import { sleep } from 'i8-ui';

export default Vue.extend({
  name: 'logout',

  data() {
    return {
      loggedOut: false,
      loggingOutMsg: 'Logging out...',
      loggedOutMsg: 'Logged out',
      solutionName: 'Overlay+',
    };
  },

  computed: {
    ...mapGetters('config', ['isWhitelabel', 'themeSolutionName', 'themeName']),
  },

  async created() {
    // Set solution name
    if (this.themeSolutionName) {
      this.solutionName = this.themeSolutionName;
    }
  },

  async mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const vm = this as any;

    try {
      await sleep();
      await vm.logoutFromProvider();
      await sleep();
      vm.loggedOut = true;
    } catch (error) {
      console.log('Error during logout', error);
    }
  },

  methods: {
    ...mapActions('auth', ['logoutFromProvider']),
    ...mapActions('config', ['appConfigLoad', 'uiConfigLoad']),
    ...mapActions('overlay', ['isReadySet']),
  },
});
