import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { UserState } from '@/store';

export * from './types';

export const user: Module<UserState, unknown> = {
  namespaced: true,
  state: {
    me: {},
    preferences: {},
  } as UserState,
  actions,
  mutations,
  getters,
};

export type UserGetters = typeof getters;
export type UserActions = typeof actions;
export type UserMutations = typeof mutations;
