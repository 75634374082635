















import Vue from 'vue';

const OLoading = Vue.extend({
  name: 'o-loading',
  props: {
    msg: {
      type: String,
      default: 'Loading...',
    },
  },
});

export { OLoading };
export default OLoading;
