export const DialogType = {
  ALERT: 'alert',
  CONFIRM: 'confirm',
  MODAL: 'modal', // for customised modals
} as const;
export type DialogType = (typeof DialogType)[keyof typeof DialogType];

export const DialogSize = {
  SMALL: 'sm',
  LARGE: 'lg',
} as const;
export type DialogSize = (typeof DialogSize)[keyof typeof DialogSize];

export const DialogStatus = {
  DEFAULT: 'default',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
  SUCCESS: 'success',
} as const;
export type DialogStatus = (typeof DialogStatus)[keyof typeof DialogStatus];

export interface DialogOpts {
  type: DialogType;
  status: DialogStatus;
  message: string;
  header: string;
  prompt: boolean;
  promptDescription: string;
  required: boolean;
  okLabel: string;
  confirmLabel: string;
  cancelLabel: string;
  resolve: Promise<boolean | string>;
  callback: DialogCallback;
}

export type DialogCallback = (resolution: string | boolean) => void;
