import Vue, { DirectiveOptions } from 'vue';

export const focusOpts = {
  inserted: (el) => {
    el.tabIndex = 0;
    el.focus();
  },
} as DirectiveOptions;

export const focusDirective = Vue.directive('focus', focusOpts);

export default {
  install(): DirectiveOptions {
    return focusDirective;
  },
};
