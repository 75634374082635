import orderBy from 'lodash/orderBy';
import { FileState, OverlayFile } from '@/store';

export const allFiles = (state: FileState): OverlayFile[] => {
  return orderBy(
    state.files,
    ['state.status', 'state.updated_at'],
    ['asc', 'asc'],
  );
};
