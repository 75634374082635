

















































import Vue from 'vue';
import find from 'lodash/find';
import { I8Icon } from 'i8-ui';
import { OPlugin } from '@/store';

// Icon library
import { faArrowToLeft } from '@fortawesome/pro-light-svg-icons/faArrowToLeft';
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons/faArrowToRight';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';

// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faArrowToLeft, faArrowToRight, faPlus);

const OPluginSelector = Vue.extend({
  name: 'o-plugin-selector',

  components: {
    I8Icon,
  },

  props: {
    plugins: {
      type: Array as () => OPlugin[],
      required: true,
    },
    activePluginId: {
      type: String,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    /**
     * Which plugin is currently being displayed
     */
    activePlugin(): OPlugin | undefined {
      if (!this.activePluginId) {
        return undefined;
      }

      return find(this.plugins, ['id', this.activePluginId]);
    },

    /**
     * The position of the active plugin cursor
     */
    cursorTop(): string {
      const n = `plugin_${this.activePluginId}`;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const refs = this.$refs as any;
      if (refs[n] && refs[n][0]) {
        return `${refs[n][0].offsetTop - 31}px`;
      }

      return '';
    },
  },

  methods: {
    /**
     * Toggle the sidebar expansion state and notify the parent component
     */
    toggleExpand(): void {
      this.$emit('update:isExpanded', !this.isExpanded);
    },

    /**
     * Notify the parent component that a plugin item has been selected
     */
    itemClicked(pluginId: string): void {
      this.$emit('itemClicked', pluginId);
    },

    /**
     * Initials of this plugin to be used when we don't have an icon
     */
    pluginInitials(name: string): string {
      const initialsRegex = name.match(/\b\w/g) || [];
      const initials = (
        (initialsRegex.shift() || '') + (initialsRegex.pop() || '')
      ).toUpperCase();
      return initials;
    },
  },
});

export { OPluginSelector };
export default OPluginSelector;
