




























/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { mapActions } from 'vuex';

import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import keyBy from 'lodash/keyBy';

import {
  OAppPage,
  OExtraData,
  OPageConfig,
  OPageRenderer,
  OActions,
} from '@/mixin';
import { today, timestampDayRange } from 'i8-ui';

export default Vue.extend({
  mixins: [OAppPage, OExtraData, OPageConfig, OPageRenderer, OActions],

  data() {
    return {
      externalData: {},
      dayRange: '',
      string: {
        title: 'Dashboard',
      },
    };
  },

  computed: {
    headerItems(): any[] {
      const vm = this as any;

      if (vm.pageTitle?.title) {
        return [
          {
            type: 'text',
            text: vm.pageTitle.title,
          },
        ];
      }

      return [
        {
          type: 'text',
          text: vm.pageName,
        },
        {
          type: 'date-filter',
          date: this.today(),
        },
      ];
    },

    /**
     * Context object for the renderer.
     *
     * Used to access data from i8-renderer schemas.
     *
     * @return { Object }
     */
    context(): any {
      const vm = this as any;
      return {
        ...this.externalData,
        ...vm.extraData.data, // additional data from config
        pagination: vm.extraData.pagination,
        dayRange: this.dayRange,
      };
    },
  },

  methods: {
    ...mapActions('config', ['viewSchemaLoad']),
    ...mapActions('document', ['documentsByTypeLoad']),
    today,
    /**
     * Load configuration data needed for the dashboard.
     *
     * Includes:
     *   - i8-renderer schema used for the layout
     *
     * @return { Promise<void> }
     */
    async loadData(): Promise<void> {
      const vm = this as any;

      try {
        vm.loadingStart();

        if (!vm.screenConfig) {
          // load document list template
          await vm.viewSchemaLoad({
            viewId: this.$route.name,
            parentViewId: this.$route.meta.parentId,
          });
        }

        // load any additional data for the page
        await vm.exdLoadData(vm.dataSrc, {
          documents: this.loadDocumentStats,
        });

        // re-run the schema pre-processor
        const dashboard = this.$refs.dashboard as any;
        if (dashboard) {
          dashboard.loadExternalData();
        }
      } catch (error) {
        vm.loadingError(error);
      } finally {
        vm.loadingComplete();
      }
    },

    /**
     * Load data needed for widgets on the dashboard.
     *
     * Each key in the `dataSrc` object in the view config will be used
     * as a key in the renderer context.
     *
     * @return { Promise<void> }
     */
    async loadDocumentStats(key: string): Promise<void> {
      const vm = this as any;

      if (!key) {
        console.warn('Unable to load document stats for', key);
        return;
      }

      // this comes from `OExtraData`
      const config = vm.extraData.config[key];

      const apiQuery = config.query;
      if (config.dayRange) {
        apiQuery.created_at = this.dayRange;
      }

      const response = await vm.documentsByTypeLoad({
        query: {
          apiQuery,
          request: pickBy({ stats: config.stats }, identity),
        },
        moduleUrlOverride: vm.routeConfig.moduleUrlOverride || undefined,
        moduleId: vm.routeConfig.moduleId || undefined,
      });

      const flattenedData = this.recursiveKeyBy(response, '_key');
      this.$set(this.externalData, key, flattenedData);
    },

    selectDate(date: string[]): void {
      this.dayRange = timestampDayRange(date[0]).join();
      this.loadData();
    },

    recursiveKeyBy(
      data: Record<string, any>,
      key: string,
    ): Record<string, any> {
      for (const prop in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (!data.hasOwnProperty(prop)) {
          // inherited prop
          continue;
        }

        if (typeof data[prop] !== 'object') {
          // primitive type
          continue;
        }

        if (Array.isArray(data[prop])) {
          data[prop] = keyBy(data[prop], key);
        }

        data[prop] = this.recursiveKeyBy(data[prop], key);
      }
      return data;
    },
  },

  watch: {
    $route: {
      async handler() {
        if (!this.dayRange) {
          this.dayRange = timestampDayRange(Date.now()).join();
        }

        await this.loadData();
      },
      deep: true,
      immediate: true,
    },
  },
});
