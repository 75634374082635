































import Vue from 'vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { mapGetters, mapActions, mapMutations } from 'vuex';

import { OPluginSelector } from './o-plugin-selector.vue';
import { OPlugin } from './o-plugin.vue';

import { OAppPage } from '@/mixin';

const OPluginSidebar = Vue.extend({
  name: 'o-plugin-sidebar',

  mixins: [OAppPage],

  components: {
    OPluginSelector,
    OPlugin,
  },

  data() {
    return {
      isExpanded: false,
      activePluginId: '',
    };
  },

  computed: {
    ...mapGetters('plugin', ['pluginList', 'pluginContext']),
  },

  methods: {
    ...mapActions('plugin', ['pluginConnected', 'pluginError']),

    /**
     * Open the sidebar and dispaly a particular plugin.
     *
     * @param {string} pluginId - the plugin to show
     */
    pluginOpen(pluginId: string) {
      this.activePluginId = pluginId;
      this.isExpanded = true;
    },

    /**
     * Toggle the expanded state for the sidebar.
     */
    toggleExpand(): void {
      this.isExpanded = !this.isExpanded;
    },
  },

  watch: {
    /**
     * Ensure that when the sidebar is opened, we show one of the plugins.
     *
     * If the user hasn't selected a plugin, we show the first in the list.
     */
    isExpanded(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      if (this.isExpanded && !this.activePluginId && vm.pluginList.length > 0) {
        // make sure to show a plugin
        this.activePluginId = vm.pluginList[0].id;
      }
    },
  },
});

export { OPluginSidebar };
export default OPluginSidebar;
