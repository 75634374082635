import Vue from 'vue';
import { mapActions, Store } from 'vuex';
import { downloadFile } from 'i8-ui';

import { OverlayAction } from './types';

const OActions = Vue.extend({
  methods: {
    ...mapActions('plugin', ['intentEnqueue']),
    ...mapActions('file', ['fileByIdDownload', 'fileByIdContent']),

    async doAction(action: OverlayAction): Promise<unknown> {
      if (!action.type) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      switch (action.type) {
        case 'overlay.link':
          return this.$router.push(action.location);

        case 'overlay.file.download':
          return vm.fileByIdDownload({ fileId: action.fileId });

        case 'overlay.downloadAsFile':
          return downloadFile(
            action.data,
            action.fileName,
            action.mime,
            action.download,
            action.bom,
          );

        case 'overlay.file.content':
          return vm.fileByIdContent(vm.$route.params.id, action.fileId);

        case 'plugin.intent':
          return vm.intentEnqueue(action.intent);

        case 'module.action':
          // eslint-disable-next-line no-case-declarations
          const store: Store<unknown> = vm.$store;

          if (!store.hasModule(action.action.moduleId)) {
            return;
          }

          return await store.dispatch(
            `${action.action.moduleId}/${action.action.action}`,
            { ...action.action.payload, store },
          );

        default:
          console.warn('unknown action type', action);
      }
    },
  },
});

export { OActions };
export default OActions;
