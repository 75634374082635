import { UserContext } from '@/store';
import { OverlayUserPrefsPayload } from './types';

// Load user preferences7
export const userPreferencesLoad = async (ctx: UserContext): Promise<void> => {
  const stored = JSON.parse(localStorage.getItem('userPreferences') || '{}');
  ctx.commit('userPreferencesLoad', stored);
};

// Set user preferences in device storage
export const userPreferencesSet = async (
  ctx: UserContext,
  payload: OverlayUserPrefsPayload,
): Promise<void> => {
  ctx.commit('userPreferencesSet', payload);
};

// Clear user preferences in device storage by namespace key
export const userPreferencesClear = async (
  ctx: UserContext,
  namespaces: string[],
): Promise<void> => {
  ctx.commit('userPreferencesClear', namespaces);
};
