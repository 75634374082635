/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api, BASE_URL } from '@/service/api.service';
import {
  AuthService,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  AuthProviderType,
  UserCredentials,
  AuthProvider,
} from '@/service/types';

export const SESSION_CHECK = 'sessions/current';
const resource = '/auth';

export const Auth = {
  // Get a list of available authentication providers
  getProviders() {
    return Api.get(`${resource}/providers`);
  },

  // login using a username and password
  loginInternal(user: UserCredentials) {
    const formData = new FormData();
    formData.append('username', user.username);
    formData.append('password', user.password);

    return Api.post(`${resource}/userpass/login`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  // Current session (TODO: Move to session)
  current() {
    return Api.get(SESSION_CHECK);
  },

  // Logout user
  logout() {
    return Api.post(`${resource}/logout`);
  },

  // Initiate password recovery
  passwordRecover(provider: string, data: any) {
    return Api.post(`${resource}/${provider}/pwd/recover`, data);
  },

  // Reset user password
  passwordReset(data: any) {
    return Api.post(`${resource}/pwd/reset`, data);
  },
} as AuthService;

export function loginRedirectUrl(provider: AuthProvider): string {
  return provider.for_spa_host && provider.for_spa_host != ''
    ? `${provider.for_spa_host}${BASE_URL}${resource}/${provider.id}/login`
    : `${BASE_URL}${resource}/${provider.id}/login`;
}

export function loginRedirectHttpMethod(provider: AuthProvider): string {
  switch (provider.type) {
    case 'oidc':
    case 'oauth2':
      return 'GET';

    default:
      return 'POST';
  }
}

export function isExternal(provider: AuthProvider): boolean {
  return provider.type !== 'userpass';
}

/**
 * External auth provider
 * Redirecting the user is done via a form since is cannot
 * be done through xhr
 */
export function loginRedirect(provider: AuthProvider): void {
  const url = loginRedirectUrl(provider);
  const method = loginRedirectHttpMethod(provider);
  const form = document.createElement('form');
  document.body.appendChild(form);
  form.method = method;
  form.action = url;
  form.submit();
}
