











































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { I8Timeline, I8Icon, I8Text, I8Link } from 'i8-ui';

import './icons';

import { DocumentEvent, PagedEvents } from '@/store';

import { OAppPage, OPageConfig, OPageRenderer, OActions } from '@/mixin';
import { EventAPIQuery } from '@/service';

interface Pagination {
  limit: number;
  cursor?: string;
}

export default Vue.extend({
  mixins: [OAppPage, OPageConfig, OPageRenderer, OActions],

  components: {
    I8Timeline,
    I8Icon,
    I8Text,
    I8Link,
  },

  props: {
    document: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pagination: {
        limit: 200, // HACK: event api pages currently return duplicates
        cursor: undefined,
      } as Pagination,
    };
  },

  computed: {
    ...mapGetters('document', ['documentEventsGet']),
    ...mapGetters('config', [
      'eventBodySchemaGet',
      'eventFooterSchemaGet',
      'eventConfigGet',
      'globalRenderContext',
    ]),

    /**
     * Document ID for the docuemnt being viewed.
     *
     * This is loaded from a parameter in the URL.
     *
     * @return { string }
     */
    documentId(): string {
      return this.$route.params.id;
    },

    /**
     * List of events and pagination data.
     *
     * @return { PagedDocuments }
     */
    pagedEvents(): PagedEvents {
      const vm = this as any;
      return vm.documentEventsGet(this.documentId);
    },

    /**
     * A list of events to show in the timeline
     *
     * @param { DocumentEvent[] }
     */
    events(): DocumentEvent[] {
      const vm = this as any;
      if (this.pagedEvents && Array.isArray(this.pagedEvents.events)) {
        return this.pagedEvents.events;
      } else {
        return [];
      }
    },

    context() {
      const vm = this as any;
      return {
        document: vm.document,
      };
    },

    apiQuery(): EventAPIQuery {
      return pickBy(this.pagination, identity) as EventAPIQuery;
    },
  },

  methods: {
    ...mapActions('document', ['documentEventsLoad']),
    ...mapActions('config', ['eventBodySchemaLoad', 'eventFooterSchemaLoad']),

    /**
     * Event title
     *
     * @return { string }
     */
    eventTitle(event: DocumentEvent): string {
      const eventTypeArr = event.type.split('.');
      const defaultTitle = startCase(eventTypeArr[eventTypeArr.length - 1]);

      return this.getEventConfigItem(event, 'header.title', defaultTitle);
    },

    /**
     * Supplementary information for a particular event.
     *
     * This is displayed on the right side of the event header.
     *
     * @return { string }
     */
    eventAside(event: DocumentEvent): string {
      return this.getEventConfigItem(event, 'header.aside', '');
    },

    /**
     * Icon for a particular event.
     *
     * This is displayed on the left side of the event title.
     *
     * @return { string }
     */
    eventIcon(event: DocumentEvent): string {
      return this.getEventConfigItem(event, 'header.icon', '');
    },

    /**
     * Status for a particular event.
     *
     * This can be used to style the event header based on traffic-light status
     *
     * @return { string }
     */
    eventStatus(event: DocumentEvent): string {
      return this.getEventConfigItem(event, 'header.status', 'default');
    },

    /**
     * Title for a particular event.
     *
     * @return { string }
     */
    getEventConfigItem(
      event: DocumentEvent,
      path: string | string[],
      fallback?: any,
    ): string {
      const vm = this as any;
      const config = vm.eventConfigGet(event.type);
      const configItem = get(config, path);
      return vm.preprocessConfig(configItem, { event }) || fallback;
    },

    /**
     * Load all data needed for this view.
     *
     * Includes:
     *   - a list of events for this document
     *   - i8-renderer schema used for each event type
     *
     * @return { Promise<void> }
     */
    async loadData(loaded?: () => void): Promise<void> {
      const vm = this as any;
      if (!this.apiQuery.cursor) {
        // we're loading for the first time
        vm.loadingStart();
      }

      try {
        await vm.documentEventsLoad({
          documentId: this.documentId,
          apiQuery: this.apiQuery,
          moduleUrlOverride: vm.$parent.moduleUrlOverride || undefined,
          moduleId: vm.$parent.moduleId || undefined,
        });

        if (this.pagedEvents.next) {
          // saving a local copy means we start from page 1
          // each time we load the page
          this.pagination.cursor = this.pagedEvents.next;
        }

        await this.loadEventSchemas(this.events);
      } catch (error) {
        vm.loadingError(error);
      } finally {
        vm.loadingComplete();

        // let the pagination component know we're done
        if (loaded) {
          loaded();
        }
      }
    },

    /**
     * Load layout configuration for a list of event types.
     */
    async loadEventSchemas(events: DocumentEvent[]): Promise<void[]> {
      const vm = this as any;

      const loadSchemas = async (eventType: string) => {
        if (!vm.eventBodySchemaGet(eventType)) {
          await vm.eventBodySchemaLoad({ eventType });
        }

        // load footer schema if required
        if (!vm.eventFooterSchemaGet(eventType)) {
          await vm.eventFooterSchemaLoad({ eventType });
        }
      };

      // ensure we're not loading the same type mulitple times
      const types = uniq(events.map((e) => e.type));

      return await Promise.all(types.map((t) => loadSchemas(t)));
    },
  },

  watch: {
    /**
     * When the document ID changes, load all data for this view.
     *
     * This is done in a watcher instead of a lifecycle hook to work around
     * cases where vue re-uses the component instance.
     */
    documentId: {
      handler(newDocumentId: string, oldDocumentId: string) {
        this.loadData();
      },
      immediate: true,
    },

    /**
     * When the events change, load layout configuration for each one.
     */
    events: {
      async handler(newEvents: DocumentEvent[]) {
        await this.loadEventSchemas(newEvents);
      },
      deep: true,
    },
  },
});
