export const TABLE_CONFIG_FILE = [
  {
    key: 'state.name',
    val: 'Name',
  },
  {
    key: 'state.content.size',
    val: 'File Size',
  },
  {
    key: 'state.updated_at',
    val: 'Updated',
  },
  {
    key: 'state.content.version',
    val: 'Version',
  },
  {
    key: 'actions',
    val: 'Actions',
  },
];
