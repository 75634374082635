import Vue from 'vue';
import { OError } from '@/component/o-error';
import { OEmpty } from '@/component/o-empty';
import { OLoading } from '@/component/o-loading';

const OPageStatus = Vue.extend({
  components: {
    OError,
    OEmpty,
    OLoading,
  },

  data() {
    return {
      page: {
        loading: false,
        loaded: false,
        error: false,
        errorMsg: 'Something has gone wrong :(',
      },
    };
  },

  methods: {
    loadingStart() {
      this.page = {
        loading: true,
        loaded: false,
        error: false,
        errorMsg: 'Something has gone wrong :(',
      };
    },

    loadingComplete() {
      this.page.loading = false;
      this.page.loaded = true;
    },

    loadingError(errorMsg: string) {
      this.page.loading = false;
      this.page.error = true;
      if (errorMsg) {
        this.page.errorMsg = errorMsg;
        console.error(errorMsg);
      }
    },
  },
});

export { OPageStatus };
export default OPageStatus;
