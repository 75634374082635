import Vue from 'vue';
import { OverlayFile, FileState } from '@/store';

export const fileListSet = (state: FileState, files: OverlayFile[]): void => {
  state.files = files;
};

export const fileCreated = (state: FileState, file: OverlayFile): void => {
  state.files.push(file);
};

export const fileDeleted = (state: FileState, fileId: string): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state.files.forEach((file: any) => {
    if (file.id === fileId) {
      Vue.set(file.state, 'status', 'deleted');
    }
  });
};
