



































/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

// lib
import { I8GlobalNav, I8Icon, I8Renderer } from 'i8-ui';
import filter from 'lodash/filter';
import find from 'lodash/find';

// components
import { OOverlayLogo } from '@/component/o-overlay-logo';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';

import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFileUser } from '@fortawesome/pro-light-svg-icons/faFileUser';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFileImport } from '@fortawesome/pro-light-svg-icons/faFileImport';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons/faFileInvoice';
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar';
import { faFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { faFilePlus } from '@fortawesome/pro-light-svg-icons/faFilePlus';
import { faUniversity } from '@fortawesome/pro-light-svg-icons/faUniversity';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faEnvelopeOpenDollar } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenDollar';
import { faMoneyCheckEditAlt } from '@fortawesome/pro-light-svg-icons/faMoneyCheckEditAlt';
import { faSackDollar } from '@fortawesome/pro-light-svg-icons/faSackDollar';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faAlarmClock } from '@fortawesome/pro-light-svg-icons/faAlarmClock';
import { faTachometerAltFast } from '@fortawesome/pro-light-svg-icons/faTachometerAltFast';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faReply } from '@fortawesome/pro-light-svg-icons/faReply';
import { faCommentLines } from '@fortawesome/pro-light-svg-icons/faCommentLines';
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons/faLightbulbOn';
import { faUserSecret } from '@fortawesome/pro-light-svg-icons/faUserSecret';

library.add(
  faHome,
  faFileInvoiceDollar,
  faCog,
  faUser,
  faUsers,
  faFile,
  faFileUser,
  faFileAlt,
  faFileCheck,
  faFileImport,
  faFileInvoice,
  faFilePlus,
  faUniversity,
  faBuilding,
  faEnvelopeOpenDollar,
  faMoneyCheckEditAlt,
  faFileAlt,
  faSackDollar,
  faAlarmClock,
  faSignOut,
  faUpload,
  faDownload,
  faTachometerAltFast,
  faCalendarDay,
  faReply,
  faCommentLines,
  faLightbulbOn,
  faUserSecret,
);

import { OPageConfig } from '@/mixin';

const OGlobalNav = Vue.extend({
  name: 'o-global-nav',

  mixins: [OPageConfig],

  components: {
    OOverlayLogo,
    I8GlobalNav,
    I8Icon,
    I8Renderer,
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  computed: {
    ...mapGetters('config', [
      'navTopItems',
      'navBottomItems',
      'logoutRoute',
      'navSchema',
    ]),
    ...mapGetters('auth', ['providers', 'session']),
    ...mapGetters('user', ['userMeGet']),
    ...mapGetters('overlay', ['dialogByNavItem']),
    ...mapGetters('config', ['isWhitelabel', 'uiConfig']),

    /* eslint-disable vue/return-in-computed-property */
    activeNavItemId(): any {
      const vm = this as any;
      const navItems = [...vm.navTopItems, ...vm.navBottom];

      if (navItems.length < 0) {
        return;
      }

      // calculate activeNavItemId according to route
      const activeNavItem = find(navItems, (navItem) => {
        if (navItem._type && navItem._type === 'separator') {
          return false;
        }

        if (!navItem.route) {
          return false;
        }

        const isActive = navItem.route.name === this.$route.name;
        const isParentActive = navItem.route.name === this.$route.meta.parentId;
        // for flat routes not under tabs but with parent
        const isParentFlatActive =
          navItem.route.name === this.$route.meta.parentViewId;
        return isActive || isParentActive || isParentFlatActive;
      });

      if (activeNavItem) {
        return activeNavItem.id;
      }
    },

    navTop(): any {
      const vm = this as any;
      return vm.filterNavItems(vm.navTopItems);
    },

    navBottom(): any {
      const vm = this as any;
      return vm.filterNavItems(vm.navBottomItems.concat(this.logoutItem));
    },

    logoutItem(): any {
      const vm = this as any;
      return {
        id: 'logout',
        order: 99,
        label: 'Log out',
        desc: vm.session.user,
        title: `Logged in as ${vm.session.user}`,
        viewId: 'logout',
        icon: 'sign-out',
        route: {
          name: vm.logoutRoute || 'logout',
        },
      };
    },
  },

  methods: {
    ...mapActions('overlay', ['updateDialogByNavItem']),
    filterNavItems(items: any): any {
      const vm = this as any;
      const processedItems = vm.preprocessConfig(items, {
        providers: vm.providers,
        config: vm.uiConfig,
      });
      return filter(processedItems, (navItem) => {
        // Filter by permission array; assumed permitted if not present
        const hasPermission = !navItem.permissions
          ? true
          : navItem.permissions.every((permission: string) =>
              vm.userMeGet?.permissions.includes(permission),
            );
        // Filter by active property; assumed active if not present
        const isActive = Object.prototype.hasOwnProperty.call(navItem, 'active')
          ? navItem.active === true
          : true;
        return hasPermission && isActive;
      });
    },
    itemClicked(item: any) {
      const vm = this as any;
      if (!item._type) return;
      if (item._type === 'dialog') {
        vm.updateDialogByNavItem(item);
      }
    },
  },
});

export { OGlobalNav };
export default OGlobalNav;
