



























import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { OAppPage } from '@/mixin';

export default Vue.extend({
  mixins: [OAppPage],

  computed: {
    ...mapGetters('auth', ['session']),
    // User display name from vuex
    userDisplayName(): string {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.session.user;
    },
  },

  methods: {
    ...mapActions('auth', ['logout']),
    // logout the current user and redired to the sign in page
    async doLogout(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      await vm.logout();
      this.$router.push({ name: 'login' });
    },
  },
});
