



















































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { OAuthProviders, OLoginForm, OVersion } from '@/component';
import { I8Spinner, sleep } from 'i8-ui';
import { appRoutes, routeError404, resetRouter } from '@/router';
import { Overlay } from '@overlay/module-sdk';

import { store } from '@/store';
import {
  AuthProvider,
  isExternal,
  loginRedirect,
  UserCredentials,
} from '@/service';

export default Vue.extend({
  name: 'login',

  components: {
    I8Spinner,
    OLoginForm,
    OAuthProviders,
    OVersion,
  },

  data() {
    return {
      loading: true,
      starting: false,
      startingMsg: 'Starting',
      showLoginForm: false,
      appVersion: '',
      solutionName: 'Overlay+',
    };
  },

  computed: {
    ...mapGetters('user', ['userHas']),
    ...mapGetters('auth', ['isAuthenticated', 'providers']),
    ...mapGetters('plugin', ['notInitialisedPluginList', 'pluginList']),
    ...mapGetters('config', [
      'allViewConfig',
      'isWhitelabel',
      'themeFavicon',
      'themeName',
      'themeSolutionName',
      'name',
    ]),
  },

  async created() {
    this.starting = true;

    this.appVersion = process.env.VUE_APP_COMMIT_SHA || '';
    console.log(this.appVersion);

    // remove any vue-router routes that have been previously generated
    resetRouter();

    // UI Config fetched in router guard, config theme
    await this.configureLoginTheme();
  },

  async mounted(): Promise<void> {
    if (!this.isAuthenticated) {
      this.starting = false;
      this.loading = false;
      return;
    }

    this.loading = false;

    await sleep(250); // Dramatic beat for 'extra secure' login

    this.initOverlay();
  },

  async beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    let redirect = false;

    // Check authentication session status
    try {
      // check if the user has a valid session
      // this will return a 401 if the user is not logged in
      await store.dispatch('auth/sessionCurrent');
    } catch (error) {
      // we get an error when we receive a 401
      // TODO: handle the 401 in vuex
    }

    // Get auth providers
    try {
      const providers: AuthProvider[] = await store.dispatch(
        'auth/loginProvidersLoad',
      );
      const isAuthenticated = store.getters['auth/isAuthenticated'];

      if (!isAuthenticated) {
        // redirect if there is only one external provider
        if (providers.length === 1 && isExternal(providers[0])) {
          loginRedirect(providers[0]);
          redirect = true;
        }

        // redirect if with defined host
        const matchedProvider = providers.find(
          (provider) =>
            provider.for_spa_host ===
            `${window.location.protocol}//${window.location.host}`,
        );
        if (matchedProvider) {
          loginRedirect(matchedProvider);
          redirect = true;
        }
      }
    } catch (error) {
      console.error('Login providers were not available....', error);
    }

    // load ui config in guard to avoid theme transition flash
    try {
      await store.dispatch('config/uiConfigLoad');
    } catch (error) {
      console.error('Unable to load UI config....', error);
    }

    if (!redirect) {
      // if redirect not required, go to next page as normal
      next();
    }
  },

  methods: {
    ...mapActions('auth', ['loginInternal']),
    ...mapActions('config', ['appConfigLoad']),
    ...mapActions('overlay', ['isReadySet', 'setFavicon']),
    ...mapActions('plugin', ['pluginListLoad', 'intentEnqueue']),

    configureLoginTheme() {
      // Set solution name
      if (this.themeSolutionName) {
        this.solutionName = this.themeSolutionName;
        document.title = this.themeSolutionName;
      }

      // Set favicon
      if (this.isWhitelabel && this.themeFavicon) {
        this.setFavicon(this.themeFavicon);
      }
    },

    /**
     * Login with username and password
     */
    async loginUsernamePassword(user: UserCredentials): Promise<void> {
      this.starting = true;
      try {
        this.startingMsg = 'Authenticating';
        await this.loginInternal(user);
      } catch (error) {
        // TODO: handle login failure
        this.starting = false;
        return;
      }

      this.initOverlay();
    },

    /**
     * Initialise the Overlay+ UI
     *
     *   - Load application config
     *   - Create app routes
     *   - load and initalise plugins
     */
    initOverlay(): void {
      // let the aplication know we're wiring thing up
      this.isReadySet(false);

      Promise.all([this.initConfig(), this.initPlugins()])
        .then(this.goToApp)
        .catch((error: Error) => {
          console.error('Error during initialisation:', error);
          return this.$router.push({
            path: 'you-shall-not-pass',
            query: {
              showLogout: 'true',
            },
          });
        });
    },

    /**
     * Configure the main Overlay+ UI
     *
     *   - Load application config
     *   - Create app routes
     */
    async initConfig(): Promise<void> {
      this.startingMsg = 'Loading App Configuration';

      // load config from API
      await this.appConfigLoad();

      this.startingMsg = 'Configuring App Routes';

      // Initialise modules
      await this.initModules().catch((error: Error) => {
        return Promise.reject(error);
      });

      // dynamically build app routes
      const routes = appRoutes(this.allViewConfig);

      // 404 must be the last route
      routes.push(routeError404);
      this.$router.addRoutes(routes);
      this.isReadySet(true);
    },

    async initModules(): Promise<void> {
      this.startingMsg = 'Initialising modules';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const pendingHooks = Overlay.getInitHooks(Vue as any).map((hook) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        hook(this as any),
      );
      await Promise.all(pendingHooks).catch((error: Error) => {
        return Promise.reject(error);
      });
    },

    /**
     * Load and initialise all plugins
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async initPlugins(): Promise<any> {
      this.startingMsg = 'Loading Plugin Configuration';

      // load plugin config from API
      await this.pluginListLoad();

      if (this.pluginList.length < 1) {
        return;
      }

      this.startingMsg = 'Initialising Plugins';

      // The initialisation process is started automatically in `o-plugin`
      // when the config has been loaded.
      //
      // Freshly loaded plugins appear in `notInitialisedPluginList` until one
      // of two things happens:
      //   - the plugin is successfully initialised
      //   - the plugin is in an error state
      //
      // When all plugins have been through this process, we resolve the promise.
      const plugin = new Promise((resolve) => {
        this.$watch('notInitialisedPluginList', (newVal) => {
          if (newVal.length === 0) {
            // we don't any plugin that haven't been initialised
            resolve(true);
          }
        });
      });

      // the plugin-sdk handshake will timeout in 10s if no response is received.
      // 6s is used here to add a buffer to make sure we reach the full timeout
      // in the sdk. Timeouts should only happen when the app is unreachable.
      //
      // If this time elapses, we reject the projmise.
      const ms = 11000;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const timeout = new Promise((resolve, reject) => {
        const id = setTimeout(() => {
          clearTimeout(id);
          // we don't want to reject as it will prevent the user from loggin in
          resolve(true);
        }, ms);
      });

      // Both our previous promises are now started and we return the one that
      // resolves first.
      //
      // either all plugins go through initialisation, or we timeout in 6s.
      return Promise.race([plugin, timeout]);
    },

    async nextPage(): Promise<string> {
      const nextPage = (this.$route.query.redirect as string) || '/';

      if (this.name === 'connect') {
        const isReportUser = await this.userHas('reports:get');
        if (nextPage === '/' && !isReportUser) {
          return '/request';
        }
      }

      return nextPage;
    },

    /**
     * Re-direct the user to them main overlay UI
     */
    async goToApp() {
      this.loadPluginConfig();

      if (!this.isAuthenticated) {
        return;
      }

      const nextPage = await this.nextPage();

      this.$router.push({ path: nextPage });
    },

    async loadPluginConfig(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      // TODO: to be removed or enhanced once onyx migrated to reporting-dev
      const intent = {
        type: 'explicit',
        pluginId: 'reporting_tools',
        action: 'envNameSet',
        showPlugin: false,
        payload: this.name,
      };

      await vm.intentEnqueue(intent);
    },
  },
});
