












































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

// Lib
import {
  I8Table,
  I8Icon,
  I8Form,
  I8Link,
  I8Filter,
  I8Menu,
  I8MenuList,
  I8MenuListItem,
  loadRenderSchemaData,
} from 'i8-ui';

import { OExport, OButtonAction } from '@/component';

import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import omit from 'lodash/omit';
import map from 'lodash/map';
import without from 'lodash/without';

import { OverlayUserPrefs } from '@/store';

import {
  OAppPage,
  OPageConfig,
  OPageRenderer,
  OExtraData,
  OActions,
} from '@/mixin';

// types
import { OverlayDocument, PagedDocuments } from '@/store/document';
import {
  DocumentAPIQuery,
  DocumentListTableConfig,
  DocumentListTableConfigSelectable,
  DocumentListTableAction,
  PaginationType,
  DocumentListOptions,
} from '@/service';

import './icons';

interface Pagination {
  cursor?: string;
  offset?: number;
  total?: number;
  limit: number;
  dataSrcName?: string;
  return_total?: boolean;
}

export default Vue.extend({
  mixins: [OAppPage, OPageConfig, OPageRenderer, OExtraData, OActions],

  components: {
    I8Table,
    I8Form,
    I8Icon,
    I8Link,
    I8Filter,
    I8Menu,
    I8MenuList,
    I8MenuListItem,
    OExport,
    OButtonAction,
  },

  data() {
    return {
      filter: {},
      tableRerender: false,
      search: {
        minChars: 3,
        errorMsg: '',
        query: {},
        formSchema: {
          elements: {
            q: {
              type: 'search',
              label: 'Search',
            },
          },
        },
      },
      pagination: {
        limit: 12,
      } as Pagination,
      columnsDisabled: [] as string[],
    };
  },

  computed: {
    ...mapGetters('document', ['documentsByTypeGet', 'documentByIdGet']),
    ...mapGetters('config', [
      'viewSchemaGet',
      'viewConfig',
      'globalRenderContext',
    ]),
    ...mapGetters('user', ['userPrefs']),

    /**
     * The `documentType` of the documents being displayed.
     *
     * @return { string }
     */
    documentType(): string {
      return this.$route.meta.documentType;
    },

    /**
     * Context object for the renderer.
     *
     * Used to access data from i8-renderer schemas.
     *
     * @return { Object }
     */
    context(): object {
      const vm = this as any;

      const context: Record<string, object> = {
        documents: this.documents,
        exportQuery: this.exportQuery,
        ...vm.extraData.data,
      };

      if (vm.screenConfig) {
        context.viewContext = vm.screenConfig.viewContext || {};
      }

      return context;
    },

    summarySchema(): boolean {
      const vm = this as any;
      return vm.getConfigItem('summarySchema', undefined);
    },

    actionsSchema(): boolean {
      const vm = this as any;
      return vm.getConfigItem('actionsSchema', undefined);
    },

    /**
     * Is keyword search enabled for this view?
     *
     * Returns `false` by default since there is work to do
     * in the backend to index particular fields for searching.
     *
     * @return { boolean }
     */
    enableSearch(): boolean {
      const vm = this as any;
      return vm.getConfigItem('search', false);
    },

    /**
     * Is refreshing the table enabled for this view?
     *
     * Returns `false` by default since there is work to do
     * in the backend to index particular fields for searching.
     *
     * @return { boolean }
     */
    enableRefresh(): boolean {
      const vm = this as any;
      return vm.getConfigItem('refresh', false);
    },

    enableExport(): boolean {
      const vm = this as any;
      return !!(vm.exportConfig && vm.exportConfig.enabled !== false);
    },

    /**
     * Data export config
     */
    exportConfig(): object {
      const vm = this as any;
      const config = vm.getConfigItem('export', false);
      return vm.preprocessConfig(config);
    },

    /**
     * Filters available on this page.
     *
     * @return { Object[] } List of i8-filter terms
     */
    filterTerms(): any[] {
      const vm = this as any;
      let filters = vm.getConfigItem('filters', []);

      if (!Array.isArray(filters)) {
        return [];
      }

      // remove filters that have been statically declared in config
      filters = filters.filter((f: any) => {
        // staticQuery could be null
        if (!this.staticQuery) return true;
        return !Object.keys(this.staticQuery).includes(f.key);
      });

      return vm.preprocessConfig(filters);
    },

    fullWidth(): boolean {
      return !!this.$route.meta.fullWidth;
    },

    /**
     *  Configuration object for the table.
     *
     *  @return { Object } can contain any i8-table property
     */
    tableConfig():
      | DocumentListTableConfig
      | DocumentListTableConfigSelectable
      | undefined {
      const vm = this as any;
      // we don't want to run this through the preproccessor
      // since it would prematurely evaluate expressions

      // default pagination type to cursor
      let tableConfig = {
        paginationType: PaginationType.CURSOR,
        ...get(vm.screenConfig, 'table'),
      };

      if (get(vm.screenConfig, 'tableWithExtraData')) {
        tableConfig = {
          ...tableConfig,
          extraData: vm.extraData.data,
        };
      }

      return tableConfig;
    },

    /**
     * Array of columns for the table
     * Preprocessed to allow conditional display depending on context
     *
     * @return { Array } i8-table columns configuration
     */
    tableColumns(): object[] {
      const vm = this as any;
      return vm.preprocessConfig(get(vm.tableConfig, 'columns') || []);
    },

    tableColumnsAvailable(): object[] {
      return this.tableColumns.filter((col: any) => col.active !== false);
    },

    tableColumnsVisible(): object[] {
      const vm = this as any;
      return (
        vm
          // Config enabled columns
          .preprocessConfig(get(vm.tableConfig, 'columns') || [])
          // User enabled columns
          .map((col: any) => {
            col.active =
              col.active === false
                ? col.active
                : !vm.columnsDisabled.includes(col.val);
            return col;
          })
      );
    },

    /**
     * Array of slots used to render custom content intable cells.
     *
     * @return { Object } i8-renderer slot object
     */
    tableSlots(): object[] {
      return get(this.tableConfig, '_slots') || [];
    },

    /**
     * Array of I8ContextItems to show in the page header
     *
     * @return { Array } I8ContextItem[]
     */
    headerItems(): any[] {
      const vm = this as any;
      const items = [] as any[];

      if (!vm.screenConfig) {
        // we're not ready yet
        return items;
      }

      // the title of this page is a simple text item
      items.push({
        type: 'text',
        text: vm.pageName,
      });

      // TODO: refactor views into a tree structure
      //       build up breadcrumbs by traversing the tree
      //       make this calculation generic and add to o-app-page

      return items.reverse();
    },

    /**
     * Actions for each row in the table.
     *
     * If none, are configured, the fallback will be used.
     *
     * @return { Object[] }
     */
    actions(): DocumentListTableAction[] {
      return get(this.tableConfig, 'actions') || [];
    },

    /**
     * i8-renderer schema for the expandable section for each row.
     *
     * @return { Object } i8-renderer schema
     */
    quickViewSchema(): any {
      const schema = get(this.tableConfig, 'quickViewSchema') || {};
      return cloneDeep(schema);
    },

    /**
     * List of documents and pagination data.
     *
     * @return { PagedDocuments }
     */
    pagedDocuments(): PagedDocuments | undefined {
      const vm = this as any;
      const pagedDocuments = vm.documentsByTypeGet(
        this.documentType,
      ) as PagedDocuments;

      return pagedDocuments;
    },

    /**
     * List of documents to show in the table.
     *
     * @return { OverlayDocument[] }
     */
    documents(): OverlayDocument[] {
      if (this.pagedDocuments && Array.isArray(this.pagedDocuments.documents)) {
        return this.pagedDocuments.documents;
      } else {
        return [];
      }
    },

    /**
     * API query decalred in the config for this view.
     *
     * @return { Object }
     */
    staticQuery(): object {
      const vm = this as any;
      const query = get(vm.screenConfig, 'query');

      // TODO: we may need another helper for items that will end up in renderSchema
      return loadRenderSchemaData(cloneDeep(query), vm.extraData.data);
    },

    /**
     * API filter that can be edited by the user with i8-filter.
     *
     * This filter object syncs with the query string in the URL which allows
     * links to this page to contain a filter.
     *
     * @return { Object }
     */
    query: {
      get(): any {
        const vm = this as any;
        return vm.$route.query;
      },
      set(newQuery: Record<string, any>): void {
        this.$router.replace({ query: newQuery });
      },
    },

    /**
     * Query used to export data
     *
     * Does not include pagination, docType or limit
     */
    exportQuery: {
      get(): DocumentAPIQuery {
        const query = pickBy(
          {
            ...this.query, // user specified
            ...this.staticQuery, // config specified
          },
          identity, // ensure we don't have empty values
        );

        return query as DocumentAPIQuery;
      },
    },

    /**
     * Complete API query object.
     *
     * Contains:
     *   - document type
     *   - user editable query which syncs to the query string
     *   - static query defined in view config
     *   - pagination data
     *
     * @return { DocumentAPIQuery }
     */
    apiQuery: {
      get(): DocumentAPIQuery {
        const query = pickBy(
          {
            type: this.documentType,
            ...this.query, // user specified
            ...this.staticQuery, // config specified
            ...this.pagination,
          },
          identity, // ensure we don't have empty values
        );

        return query as DocumentAPIQuery;
      },
    },

    /**
     * Data to be sent to the pagination component
     */
    paginationOptions(): any {
      switch (this.tableConfig?.paginationType) {
        case PaginationType.ADVANCED:
          return {
            offset: this.pagedDocuments ? this.pagedDocuments.offset ?? 0 : 0,
            limit: this.pagination.limit,
            total: this.pagedDocuments ? this.pagedDocuments.total ?? 0 : 0,
          };
        case PaginationType.CURSOR:
        default:
          return {
            next: this.pagedDocuments ? this.pagedDocuments.next : '',
            limit: this.pagination.limit,
          };
      }
    },

    defaultPaginationQuery(): Pagination {
      const limit =
        this.listPrefs?.pageSize ||
        this.listOptions?.pageSizeDefault ||
        this.pagination.limit;
      switch (this.tableConfig?.paginationType) {
        case PaginationType.ADVANCED:
          return {
            offset: 0,
            limit,
            return_total: true,
          };
        case PaginationType.CURSOR:
        default:
          return {
            cursor: '',
            limit,
            // IE has some delay on pagination change
            return_total: true,
          };
      }
    },

    /**
     * Array of I8ContextItems to show in the page header
     *
     * TODO: standarside this when we revisit breadcumbs
     *
     * @return { Array } I8ContextItem[]
     */
    breadcrumbs(): object[] {
      const vm = this as any;
      const items: object[] = [];

      const result = vm.getConfigItem('breadcrumbs');
      if (result) {
        return result;
      }

      // this changes depending on if we're in a tabbed view or not
      const viewId = this.$route.meta.parentId
        ? this.$route.meta.parentId
        : this.$route.name;

      const view = vm.viewConfig(viewId);

      if (!view) {
        return items;
      }

      const viewConfig = vm.parentConfig(view);

      items.push({
        type: 'text',
        text: viewConfig && viewConfig.name,
      });

      let parentView = vm.viewConfig(view.parentViewId);
      while (parentView) {
        const parentConfig = vm.parentConfig(parentView);

        items.unshift({
          type: 'link',
          text: parentConfig && parentConfig.name,
          location: {
            name: parentView.id,
          },
        });

        parentView = vm.viewConfig(parentView.parentViewId);
      }

      return items;
    },

    headerActions(): object[] {
      const vm = this as any;
      return get(vm.screenConfig, 'title.actions.schema');
    },

    pageTitleSchema(): object {
      const vm = this as any;
      return get(vm.screenConfig, 'pageTitleSchema');
    },

    listOptions(): DocumentListOptions | undefined {
      return this.tableConfig?.options;
    },

    listPrefs(): OverlayUserPrefs {
      const vm = this as any;
      return vm.userPrefs(vm.listOptions?.listNamespace);
    },
    /**
     * return bool if url should be overwritten with module url.
     *
     *
     * @return { string }
     */
    moduleUrlOverride(): boolean {
      const vm = this as any;
      let config = vm.viewConfig(this.$route.name);
      if (config && config.moduleUrlOverride) {
        return config.moduleUrlOverride as boolean;
      }
      config = vm.viewConfig(this.$route.meta.parentId);
      if (config && config.moduleUrlOverride) {
        return config.moduleUrlOverride as boolean;
      }
      return false;
    },

    /**
     * return moduleId defined in rfi config.
     *
     *
     * @return { string }
     */
    moduleId(): string {
      const vm = this as any;
      let config = vm.viewConfig(this.$route.name);
      if (config && config.moduleId) {
        return config.moduleId as string;
      }
      config = vm.viewConfig(this.$route.meta.parentId);
      if (config && config.moduleId) {
        return config.moduleId as string;
      }
      return '';
    },
  },

  methods: {
    ...mapActions('plugin', ['setPluginContext']),
    ...mapActions('document', ['documentsByTypeLoad', 'documentByIdLoad']),
    ...mapActions('config', ['viewSchemaLoad']),
    ...mapActions('pubsub', ['subscribe', 'unsubscribe']),
    ...mapActions('user', ['userPreferencesSet', 'userPreferencesClear']),

    get,
    cloneDeep,
    str: JSON.stringify,

    /**
     * Load all data needed for this view.
     *
     * Includes:
     *   - Screen config
     *   - document list
     *
     * @return { Promise<void> }
     */
    async loadData(): Promise<void> {
      const vm = this as any;

      try {
        vm.loadingStart();

        if (!vm.screenConfig) {
          // only load view config if we haven't already
          await vm.viewSchemaLoad({
            viewId: this.$route.name,
            parentViewId: this.$route.meta.parentId,
          });
        }

        // load any additional data for the page
        // some data will need to be loaded before fetch document
        await vm.exdLoadData(vm.dataSrcBefore);

        // notify plugins what the user is looking at
        vm.setPluginContext({
          type: 'document.list',
          apiQuery: this.apiQuery,
        });

        // Get user column preferences
        this.columnsDisabled = vm.listPrefs?.columnsDisabled || [];

        // load document list _after_ we've loaded view config
        // config can include an api filter that modifes the request
        await vm.documentsByTypeLoad({
          query: {
            apiQuery: this.apiQuery,
          },
          moduleUrlOverride: this.moduleUrlOverride,
          moduleId: this.moduleId,
        });

        // load another for additional data
        // some data will need to be loaded after fetch document
        await vm.exdLoadData(vm.dataSrc);

        // re-run the schema pre-processor
        const title = this.$refs.title as any;
        if (title) {
          title.loadExternalData();
        }
      } catch (error) {
        vm.loadingError(error);
      } finally {
        vm.loadingComplete();
      }
    },

    /**
     * Perform a keyword search for documents.
     *
     * @return { void }
     */
    searchDocuments(payload: { q: string }): void {
      if (!payload.q || payload.q.length >= this.search.minChars) {
        // updating the query string will triger an api query
        this.query = payload.q
          ? defaults(payload, this.query)
          : omit(this.query, 'q');

        this.search.errorMsg = '';

        // make sure we start at the first page of the results
        switch (this.tableConfig?.paginationType) {
          case PaginationType.ADVANCED:
            this.pagination.offset = 0;
            break;
          case PaginationType.CURSOR:
          default:
            this.pagination.cursor = '';
        }
      } else {
        const minChars = this.search.minChars;
        this.search.errorMsg = `A minimum ${minChars} characters is required.`;
      }
    },

    /**
     * Load a page of data from the API.
     */
    loadPage(pagination: Pagination): void {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { dataSrcName, ...rest } = pagination;
      this.pagination = { ...rest };

      this.loadData();
    },

    /**
     * Called when the user selects a row in the table.
     */
    async selectRow(row: any): Promise<void> {
      const vm = this as any;

      if (!row) {
        vm.setPluginContext({
          type: 'document.list',
          apiQuery: this.apiQuery,
        });
        return;
      }

      let document = vm.documentByIdGet(row.id);

      // do we already have this document in memory?
      if (!document) {
        // load this document from the api
        await vm.documentByIdLoad({ documentId: row.id });
        document = vm.documentByIdGet(row.id);
      }

      // esnure the plugin knows about a selected document
      vm.setPluginContext({
        type: 'document',
        meta: omit(document, ['state']),
      });
    },

    setPageSize(pageSize: number) {
      const vm = this as any;
      if (!this.listOptions) return;
      vm.pagination.limit = pageSize;
      vm.userPreferencesSet({
        userPrefs: { pageSize: pageSize },
        userPrefsNamespace: this.listOptions.listNamespace,
      });
      vm.loadData();
    },

    setColumnVisibility(column: string) {
      const vm = this as any;
      const index = this.columnsDisabled.indexOf(column);

      if (index === -1) {
        this.columnsDisabled.push(column);
      } else {
        this.columnsDisabled.splice(index, 1);
      }

      vm.userPreferencesSet({
        userPrefs: { columnsDisabled: this.columnsDisabled },
        userPrefsNamespace: this.listOptions?.listNamespace,
      });
    },

    async resetListOptions() {
      const vm = this as any;
      if (!this.listOptions) return;
      vm.userPreferencesClear([this.listOptions.listNamespace]);
      this.columnsDisabled.splice(0);
      vm.pagination.limit = this.listOptions.pageSizeDefault;
      vm.loadData();
    },
  },

  watch: {
    /**
     * When the name of the route changes, load all data for this view.
     *
     * This is done in a watcher instead of a lifecycle hook to work around
     * cases where vue re-uses the component instance.
     *
     * The component instance is re-used when this view is included in the
     * `document.tabs` view
     */
    $route: {
      handler() {
        // make sure we start at the first page of the results
        this.pagination = {
          ...this.defaultPaginationQuery,
        };
        this.loadData();
      },
      deep: true,
      immediate: true,
    },

    documents: {
      handler(newDocuments, oldDocuments) {
        const vm = this as any;

        // subscribe to changes for the documents in the table
        const newDocIds = map(newDocuments, (d) => d.id);

        for (const id of newDocIds) {
          vm.subscribe({ tags: [`document=${id}`] });
        }

        // unsubscribe to previous list of documents
        const oldDocIds = map(oldDocuments, (d) => d.id);
        for (const id of without(oldDocIds, ...newDocIds)) {
          vm.unsubscribe({ tags: [`document=${id}`] });
        }
      },
      immediate: true,
    },

    'tableConfig.paginationType': {
      // only do it once
      handler() {
        this.pagination = { ...this.defaultPaginationQuery };
      },
    },
  },

  beforeCreate() {
    const vm = this as any;
    vm.$store.dispatch('user/userPreferencesLoad');
  },

  /**
   * Unsubscribe to document changes as the user leaves the page
   */
  beforeRouteLeave(to, from, next): void {
    const vm = this as any;

    const docIds = map(this.documents, (d) => d.id);
    for (const id of docIds) {
      vm.unsubscribe({ tags: ['document', id] });
    }

    next();
  },
});
