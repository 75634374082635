


































































import Vue, { PropOptions } from 'vue';
import { mapActions, mapState } from 'vuex';
import { OverlayState } from '@/store';

import {
  DialogCallback,
  DialogOpts,
  DialogSize,
  DialogStatus,
  DialogType,
} from './types';

import { I8Icon, I8InputTextarea, I8Modal } from 'i8-ui';

import './icons';

const ODialog = Vue.extend({
  name: 'o-dialog',

  components: {
    I8Icon,
    I8InputTextarea,
    I8Modal,
  },

  data: function () {
    return {
      showDialog: this.show,
      promptValue: '',
      isValid: false,
    };
  },

  props: {
    show: {
      type: Boolean,
      default: null,
    },
    type: {
      type: String,
      default: 'alert',
    } as PropOptions<DialogType>,
    size: {
      type: String,
      default: '' as PropOptions<DialogSize>,
    },
    header: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: 'Hello world!',
    },
    okLabel: {
      type: String,
      default: 'Ok',
    },
    confirmLabel: {
      type: String,
      default: 'Confirm',
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    callback: {
      type: Function,
      default: null,
    } as PropOptions<DialogCallback>,
    prompt: {
      type: Boolean,
    },
    promptDescription: {
      type: String,
    },
    promptPlaceholder: {
      type: String,
      default: 'Provide additional comment',
    },
    required: {
      type: Boolean,
    },
    status: {
      type: String,
      default: 'default',
    } as PropOptions<DialogStatus>,
    className: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState<OverlayState>('overlay', ['dialog']),

    propMode(): boolean {
      return typeof this.show === 'boolean';
    },

    shown(): boolean {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return this.propMode ? this.showDialog : vm.dialog.show;
    },

    config(): DialogOpts {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return {
        ...this.$props,
        ...vm.dialog.options,
      };
    },

    isConfirm(): boolean {
      return this.config.type === DialogType.CONFIRM;
    },
    isAlert(): boolean {
      return this.config.type === DialogType.ALERT;
    },
    isModal(): boolean {
      return this.config.type === DialogType.MODAL;
    },

    isWithConfirm(): boolean {
      // is customised modal
      if (this.isModal) {
        return !!this.config.confirmLabel;
      }
      // is confirm or alert
      return true;
    },

    isWithCancel(): boolean {
      // is customised modal
      if (this.isModal) {
        return !!this.config.cancelLabel;
      }
      // is confirm or alert
      return this.isConfirm;
    },

    showPrompt(): boolean {
      return this.config.prompt;
    },

    icon(): string | null {
      const iconMap: Record<string, string | null> = {
        info: `${this.isConfirm ? 'question' : 'info'}-circle`,
        warning: 'exclamation-triangle',
        danger: 'times-hexagon',
        success: 'check-circle',
        default: null,
      };

      return iconMap[this.config.status];
    },
  },

  methods: {
    ...mapActions('overlay', ['dialogResolve']),

    validatePrompt(isValid: boolean) {
      this.isValid = isValid;
    },

    async cancel(): Promise<void> {
      return this.resolve(false);
    },

    async confirm(): Promise<void> {
      if (!this.showPrompt) {
        return this.resolve(true);
      }

      if (this.showPrompt) {
        if (this.config.required && !this.isValid) {
          return;
        }

        const promptValue = this.promptValue?.trim();
        return this.resolve(promptValue || true);
      }
    },

    async resolve(resolution: string | boolean): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      if (this.propMode) {
        this.showDialog = false;
      }

      if (this.callback) {
        return this.callback(resolution);
      }

      this.promptValue = '';
      return await vm.dialogResolve(resolution);
    },
  },
});

export { ODialog };
export default ODialog;
