import Vue from 'vue';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface StateTransitions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [state: string]: { [transition: string]: (...args: any[]) => void };
}

interface PluginState {
  initial: 0;
  connecting: 1;
  connected: 2;
  error: 3;
}

export const OPluginState = Vue.extend({
  data() {
    return {
      page: {
        state: 0,
      },
      state: {
        initial: 0,
        connecting: 1,
        connected: 2,
        error: 3,
      } as PluginState,
    };
  },

  computed: {
    isInitial(): boolean {
      return this.page.state === this.state.initial;
    },

    isConnecting(): boolean {
      return this.page.state === this.state.connecting;
    },

    isConnected(): boolean {
      return this.page.state === this.state.connected;
    },

    isError(): boolean {
      return this.page.state === this.state.error;
    },
  },

  methods: {
    pluginConnecting(): void {
      this.changeState(this.state.connecting);
    },

    pluginConnected(): void {
      this.changeState(this.state.connected);
    },

    pluginError(error: Error) {
      this.changeState(this.state.error);
      if (error) {
        console.error(error);
      }
    },

    changeState(state: number): void {
      if (this.isStateValid(state)) {
        this.page.state = state;
      } else {
        throw new Error(`Unknown state provided: ${state}`);
      }
    },

    isStateValid(state: number): boolean {
      return Object.values(this.state).includes(state);
    },
  },
});
