import {
  PluginContext,
  OPlugin,
  OPluginIntent,
  OPluginContext,
  PluginAction,
} from '@/store';
import { Plugin } from '@/service';

// Load the application config
export const pluginListLoad = async (ctx: PluginContext): Promise<void> => {
  const res = await Plugin.getAll();
  if (res.status === 200) {
    const list: OPlugin[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (const application of res.data.data) {
      for (const plugin of application.plugins) {
        list.push({
          id: plugin.slug,
          name: plugin.name,
          href: plugin.address,
          actions: [
            {
              name: 'show',
            },
            {
              name: 'reportItemSummary',
            },
            {
              name: 'reportItemSummaryDownload',
            },
            {
              name: 'systemSummary',
            },
            {
              name: 'scheduleRetry',
            },
            {
              name: 'scheduleIgnore',
            },
            {
              name: 'reportItemExport',
            },
            {
              name: 'reportExport',
            },
            {
              name: 'reportStats',
            },
            {
              name: 'reportItemRulesRetry',
            },
            {
              name: 'envNameSet',
            },
            {
              name: 'transactionsGet',
            },
            {
              name: 'transactionExport',
            },
          ] as PluginAction[],
          badge: { color: 'red', count: 0 },
          status: 'initial',
          manifest: { color: '#48bb78' },
        });
      }
    }
    // Send the plugin list to vuex
    ctx.commit('pluginListSet', list);
  }
};

export const pluginConnected = async (
  ctx: PluginContext,
  plugin: OPlugin,
): Promise<void> => {
  ctx.commit('pluginConnected', plugin);
};

export const pluginError = async (
  ctx: PluginContext,
  plugin: OPlugin,
): Promise<void> => {
  ctx.commit('pluginError', plugin);
};

export const setPluginContext = async (
  ctx: PluginContext,
  context: OPluginContext,
): Promise<void> => {
  ctx.commit('setPluginContext', context);
};

// handle an intent
export const intentEnqueue = async (
  ctx: PluginContext,
  intent: OPluginIntent,
): Promise<unknown> => {
  // enqueue an intent for a single plugin
  const enqueue = async (pluginIntent: OPluginIntent, pluginId: string) => {
    // actions registered by this plugin
    const actions = ctx.getters.pluginActions(pluginId);

    if (actions.length === 0) {
      console.warn(pluginId, 'has no available actions');
      return;
    }

    if (!actions.includes(intent.action)) {
      console.warn(pluginId, 'does not support this action', intent.action);
      return;
    }

    // add the intent to the intent queue
    // using a promise so the caller is aware of the result
    const intentPromise = new Promise((resolve, reject) => {
      ctx.commit('intentEnqueue', {
        intent: pluginIntent,
        pluginId,
        resolve,
        reject,
      });
    });

    const ms = 10000; // 10 seconds
    const intentTimeout = new Promise((_, reject) => {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        reject(`intent timeout in ${ms / 1000} seconds`);
      }, ms);
    });

    return Promise.race([intentPromise, intentTimeout]);
  };

  switch (intent.type) {
    case 'explicit':
      if (intent.pluginId) {
        return enqueue(intent, intent.pluginId);
      } else {
        console.warn('explicit intents must have a plugin id', intent);
      }
      break;

    case 'broadcast':
      ctx.getters.pluginList.forEach((plugin: OPlugin) => {
        return enqueue(intent, plugin.id);
      });
      break;

    default:
      console.warn('unable to enqueue intent with unknown type', intent);
      break;
  }
};
