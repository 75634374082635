






















import Vue from 'vue';
const DocumentNav = Vue.extend({
  name: 'document-nav',

  props: {
    documentType: {
      type: String,
      required: true,
    },
    navItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loaded: false,
    };
  },

  mounted() {
    this.loaded = true;
  },

  computed: {
    activeItemStyle() {
      let style = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const navEl: any = this.$refs[`nav_${this.$route.name}`];
      if (navEl && navEl[0]) {
        style = {
          width: `${navEl[0].clientWidth}px`,
          left: `${navEl[0].offsetLeft}px`,
        };
      } else {
        style = {
          width: '100px',
          display: 'none',
        };
      }
      return style;
    },
  },

  methods: {
    isRouteActive(slug: string): boolean {
      return this.$route.name === slug;
    },
  },
});

export { DocumentNav };
export default DocumentNav;
