import { render, staticRenderFns } from "./o-saved-notification.vue?vue&type=template&id=5b89a1a9&scoped=true"
import script from "./o-saved-notification.vue?vue&type=script&lang=ts"
export * from "./o-saved-notification.vue?vue&type=script&lang=ts"
import style0 from "./o-saved-notification.vue?vue&type=style&index=0&id=5b89a1a9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../common/temp/node_modules/.pnpm/vue-loader@15.11.1_01a74382766bfa0784a6747bb7c3efbb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b89a1a9",
  null
  
)

export default component.exports