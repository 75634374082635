









import Vue from 'vue';

import { OAppPage } from '@/mixin';

export default Vue.extend({
  mixins: [OAppPage],
});
