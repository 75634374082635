import Vue from 'vue';
import Router from 'vue-router';
import { store } from '@/store';

Vue.use(Router);

export const createRouter = (): Router =>
  new Router({
    mode: 'history',

    routes: [
      {
        path: '/login',
        name: 'login',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/screen/auth/login.vue'),
        meta: {
          public: true, // Allow access to even if not logged in
          loggedOutOnly: true, // Allow access only if not logged in
        },
      },
      {
        path: '/logout',
        name: 'logout',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/screen/auth/logout.vue'),
      },
      {
        path: '/you-shall-not-pass',
        name: 'auth.error',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/screen/auth/error.vue'),
        meta: {
          public: true, // Allow access to even if not logged in
        },
      },
    ],
  });

export const router = createRouter();

router.beforeEach((to, from, next) => {
  // is this a public route?
  const isPublic = to.matched.some((route) => route.meta.public);

  // is this route only available to logged out users?
  const loggedOutOnly = to.matched.some((route) => route.meta.loggedOutOnly);

  // is the user currently logged in?
  const isAuthenticated = store.getters[`auth/isAuthenticated`];

  if (!isPublic && !isAuthenticated) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath }, // Redirect the user to after login
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (isAuthenticated && loggedOutOnly) {
    return next('/');
  }

  // is the user authorised / have permissions for the route?
  const routePermissions: string[] = to.meta.permissions;

  if (routePermissions?.length) {
    const userPermissions: string[] =
      store.getters[`user/userMeGet`]?.permissions ?? [];
    const isAuthorised = routePermissions.every((permission) =>
      userPermissions.includes(permission),
    );

    if (!isAuthorised) {
      console.error(
        'Not authorised for route:',
        to.name,
        '\nRequired:',
        routePermissions,
        '\nHad:',
        userPermissions,
      );
      return next('/you-shall-not-pass');
    }

    // console.debug('Authorised for route:', to.name)
  }

  // Set title from route meta (or nearest parent)
  const defaultTitle = store.getters[`config/themeSolutionName`];
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (defaultTitle) {
    document.title = defaultTitle;
  }

  next();
});

// this is needed until we can programmatically remove routes
// https://github.com/vuejs/vue-router/issues/1234
export const resetRouter = (): void => {
  const newRouter = createRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (router as any).matcher = (newRouter as any).matcher; // the important part
};
