import Vue from 'vue';
import Vuex from 'vuex';

// modules
import { overlay } from './overlay';
import { auth } from './auth';
import { document } from './document';
import { file } from './file';
import { user } from './user';
import { config } from './config';
import { plugin } from './plugin';
import { pubsub } from './pubsub';
import { PubSubPlugin } from './pubsub/plugin';
import { PluginPlugin } from './plugin/plugin';

export * from './overlay';
export * from './auth';
export * from './document';
export * from './file';
export * from './user';
export * from './config';
export * from './plugin';
export * from './pubsub';
export * from './types';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    overlay,
    auth,
    document,
    file,
    user,
    config,
    plugin,
    pubsub,
  },
  plugins: [
    PubSubPlugin, // web socket updates
    PluginPlugin, // side bar plugin updates
  ],
});
