














import Vue from 'vue';

import { I8Icon } from 'i8-ui';

// Icon library
import { faEmptySet } from '@fortawesome/pro-light-svg-icons/faEmptySet';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faEmptySet);

const OEmpty = Vue.extend({
  name: 'o-empty',

  components: {
    I8Icon,
  },

  props: {
    empty: {
      type: String,
    },
  },
});

export { OEmpty };
export default OEmpty;
