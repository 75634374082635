














import Vue from 'vue';
// lib
import { I8Form } from 'i8-ui';

const OLoginForm = Vue.extend({
  name: 'o-login-form',

  components: {
    I8Form,
  },

  data() {
    return {
      credentials: {
        username: '',
        password: '',
      },
      loginForm: {
        elements: {
          username: {
            type: 'text',
            label: 'Username',
            autocomple: 'off',
          },
          password: {
            type: 'password',
            label: 'Password',
            autocomple: 'off',
          },
        },
      },
    };
  },

  methods: {
    // User login with username and password
    login(): void {
      this.$emit('login', this.credentials);
    },
  },
});

export { OLoginForm };
export default OLoginForm;
