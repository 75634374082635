import { AuthState } from '@/store';
import { AuthProvider } from '@/service';

// Successful login
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const loggedIn = (state: AuthState, data: any): void => {
  state.isAuthenticated = true;
  state.claims = data.claims;
  state.session = data;
};

// Successful logout
export const loggedOut = (state: AuthState): void => {
  state.isAuthenticated = false;
  state.claims = {};
};

// Providers available
export const providersListSet = (
  state: AuthState,
  providers: AuthProvider[],
): void => {
  state.providers = providers;
};
