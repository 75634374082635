/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { DataSrc } from '@/store';
import { loadRenderSchemaData } from 'i8-ui';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import pick from 'lodash/pick';

const OPageConfig = Vue.extend({
  computed: {
    ...mapGetters('config', [
      'viewSchemaGet',
      'viewConfig',
      'globalRenderContext',
    ]),
    ...mapGetters('user', ['userMeGet']),
    ...mapGetters('auth', ['session']),

    /**
     * Configuration object for this view.
     *
     * This is loaded from a JSON file referenced in config.json.
     *
     * @return { Object }
     */
    screenConfig(): any {
      const vm = this as any;
      return vm.viewSchemaGet(this.$route.name);
    },

    routeConfig(): object {
      const vm = this as any;
      return vm.viewConfig(this.$route.name);
    },

    /**
     * Name of this page
     *
     * used to create breadcrumbs
     *
     *  @return { string }
     */
    pageName(): string {
      return this.getConfigItem('name', '');
    },

    /**
     * Page title config object
     *
     *  @return { object }
     */
    pageTitle(): object {
      return this.getConfigItem('title');
    },

    /**
     * Page title actions render schema
     *
     * @return { Object } i8-renderer schema
     */
    titleActionsSchema(): object {
      return this.getConfigItem('title.actions.schema', {});
    },

    /**
     * Additional data sources declared in config
     */
    dataSrc(): DataSrc {
      return this.getConfigItem('dataSrc', {});
    },

    /**
     * Additional data sources declared in config. Intended to be called before main document API
     */
    dataSrcBefore(): DataSrc {
      return this.getConfigItem('dataSrcBefore', {});
    },

    /**
     * Layout configuration for this view.
     *
     * @return { Object } i8-renderer schema
     */
    renderSchema(): object {
      return this.getConfigItem('schema', {});
    },

    /**
     * Context object for the renderer.
     *
     * Used to access data from i8-renderer schemas.
     *
     * @return { Object }
     */
    renderContext(): any {
      const vm = this as any;

      // we can't use `preprocessConfig` here because it uses this object
      // as part of the render context which causes an infinite loop
      const preprocess = (x: object): object => {
        return loadRenderSchemaData(cloneDeep(x), vm.context);
      };

      // context for the whole app
      const globalContext = vm.globalRenderContext || {};

      // User context
      const userContext = vm.userMeGet || {};

      // session data
      const session = vm.session || {};

      // context for this particular view
      const viewContext =
        vm.screenConfig && vm.screenConfig.viewContext
          ? preprocess(vm.screenConfig.viewContext)
          : {};

      const routeContext = pick(this.$route, [
        'name',
        'path',
        'fullPath',
        'params',
        'query',
      ]);

      // context this screen type
      const context = vm.context || {};

      return {
        globalContext,
        viewContext,
        user: userContext,
        session,
        routeContext,
        ...context,
      };
    },
  },

  methods: {
    ...mapActions('config', ['viewSchemaLoad']),

    preprocessConfig<T = any>(x: object, extraContext?: object): T {
      return loadRenderSchemaData(cloneDeep(x), {
        ...this.renderContext,
        ...extraContext,
      });
    },

    getConfigItem(path: string | string[], fallback?: any): any {
      const config = get(this.screenConfig, path);
      return config ? this.preprocessConfig(config) : fallback;
    },

    parentConfig(parentView: any): object | undefined {
      const vm = this as any;

      if (!parentView) {
        return;
      }

      const parentConfig = vm.viewSchemaGet(parentView.id);

      if (!parentConfig) {
        // we don't need to `await` here
        // loading the data will cause a computed prop to re-compute
        vm.viewSchemaLoad({
          viewId: parentView.id,
        });
      }

      return this.preprocessConfig(parentConfig);
    },
  },
});

export { OPageConfig };
export default OPageConfig;
