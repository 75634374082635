import {
  Document,
  DocumentQuery,
  PagedOverlayDocuments,
  DocumentEventQuery,
  EventAPIQuery,
} from '@/service';
import { DocumentContext, OverlayDocument, DocumentEvent } from '@/store';

// Load a single document by document id
export const documentByIdLoad = async (
  ctx: DocumentContext,
  payload: {
    documentId: string;
    moduleUrlOverride?: boolean;
    moduleId?: string;
  },
): Promise<void> => {
  const response = await Document.getById(
    payload.documentId,
    payload.moduleUrlOverride,
    payload.moduleId,
  );
  ctx.commit('documentsByIdSet', response.data);
  return response.data;
};

// Load a single document by document id
export const documentByIdUpdate = async (
  ctx: DocumentContext,
  document: OverlayDocument,
): Promise<void> => {
  ctx.commit('documentsByIdSet', document);
};

// Get a list of documents by document type
export const documentsByTypeLoad = async (
  ctx: DocumentContext,
  payload: {
    query: DocumentQuery;
    moduleUrlOverride?: boolean;
    moduleId?: string;
  },
): Promise<PagedOverlayDocuments> => {
  const request = payload.query.request || undefined;
  const response = await Document.getByType(
    payload.query.apiQuery,
    request,
    payload.moduleUrlOverride,
    payload.moduleId,
  );

  if (!payload.query.skipCommit) {
    ctx.commit('documentsByTypeSet', {
      documentType: payload.query.apiQuery.type,
      documents: response.data.data ? response.data.data : [],
      next: response.data.next,
      offset: response.data.offset,
      limit: response.data.limit,
      total: response.data.total,
    });
  }

  return response.data;
};

// Get a list of document events by document id
export const documentEventsLoad = async (
  ctx: DocumentContext,
  payload: {
    documentId: string;
    query: DocumentEventQuery;
    moduleUrlOverride?: boolean;
    moduleId?: string;
    apiQuery: EventAPIQuery;
  },
): Promise<void> => {
  const response = await Document.getEvents(payload.documentId, payload.apiQuery, payload.moduleUrlOverride, payload.moduleId);

  const commitPayload = {
    documentId: payload.documentId,
    events: response.data.data ? response.data.data : [],
    next: response.data.next,
  };

  if (payload.apiQuery.cursor) {
    // append to an existing list of events
    ctx.commit('documentEventsAdd', commitPayload);
  } else {
    // start a new list of events
    ctx.commit('documentEventsSet', commitPayload);
  }

  return response.data;
};

export const documentEventsAdd = async (
  ctx: DocumentContext,
  payload: { documentId: string; events: DocumentEvent[] },
): Promise<void> => {
  ctx.commit('documentEventsAdd', payload);
};
