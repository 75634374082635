









































































































/* eslint-disable @typescript-eslint/ban-types */
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import identity from 'lodash/identity';

import { I8Filter, I8Modal, I8Panel, I8Alert, I8Icon } from 'i8-ui';
import { OverlayState, ONotification } from '@/store';

import { OActions } from '@/mixin';
import { ToastObject } from '../o-toast/types';

import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faUpload);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface I8TermBase {
  key: string;
  label: string;
  type: string;
}

interface RequiredTerms {
  anyOf: boolean;
  allOf: boolean;
  oneOf: boolean;
}

const OExport = Vue.extend({
  name: 'o-export',

  mixins: [OActions],

  components: {
    I8Alert,
    I8Filter,
    I8Modal,
    I8Panel,
    I8Icon,
  },

  props: {
    query: {
      type: Object,
      required: true,
    },
    terms: {
      type: Array as () => object[],
      required: true,
      default: () => [],
    },
    exportAction: {
      type: Object,
      default: () => new Object(),
    },
    notificationAction: {
      type: Object,
      default: () => new Object(),
    },
    constraint: {
      type: Object,
      default: () => new Object(),
    },
    anyOf: {
      type: Array as () => object[],
      default: () => [],
    },
    oneOf: {
      type: Array as () => string[],
      default: () => [],
    },
    allOf: {
      type: Array as () => string[],
      default: () => [],
    },
    dataFormat: {
      type: String,
      default: 'csv',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showModal: false,
      isFilterValid: true,
      requiredTerms: {
        anyOf: true,
        allOf: true,
        oneOf: true,
      } as RequiredTerms,
      string: {
        exportBtnText: 'Export',
        modal: {
          header: 'Export Data',
          searchHeader: 'Search Text',
          filterHeader: 'Filters',
          dataFilterHelper: 'Data will be exported using the above filters',
          fileLocationHelper:
            'When your export is ready, it will be available in the Export Jobs page.',
          missing: {
            allOf: 'Exports must include:',
            anyOf: 'Exports must include at least one of:',
            oneOf: 'Exports must include exactly one of:',
          },
          confirmBtnText: 'Export',
          cancelBtnText: 'Cancel',
        },
        notification: {
          started: 'Your export is being processed',
          cannotStart: 'Unable to begin export',
        },
      },
    };
  },

  computed: {
    ...mapState<OverlayState>('overlay', ['notifications']),
    ...mapGetters('overlay', ['nextNotification']),

    /**
     * Does the query include a keyword search?
     */
    hasSearchText(): boolean {
      // eslint-disable-next-line no-prototype-builtins
      return this.query.hasOwnProperty('q');
    },

    /**
     * Does the query include items from the filter terms?
     */
    hasFilter(): boolean {
      return !isEmpty(omit(this.query, 'q'));
    },

    /**
     * Do we have all the terms we're required to?
     */
    hasRequired(): boolean {
      return Object.values(this.requiredTerms).every(identity);
    },
  },

  methods: {
    ...mapActions('overlay', ['notificationCreate']),

    /**
     * Show the export confirmation modal
     */
    show(): void {
      this.showModal = true;
    },

    /**
     * hide the export confirmation modal
     */
    cancel(): void {
      this.showModal = false;
    },

    /**
     * Begin async data export
     */
    async startExport(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      // hide the modal
      this.showModal = false;

      let exportId;
      try {
        const response = await vm.doAction(this.exportAction);
        exportId = response.id;
      } catch (error) {
        console.error('unable to start export:', error, this.exportAction);
      }

      const toast: ONotification = exportId
        ? {
            message: this.string.notification.started,
            type: 'info',
            action: [
              {
                text: vm.notificationAction.text,
                onClick: (e: Event, t: ToastObject) => {
                  t.goAway(0);
                  vm.doAction(vm.notificationAction);
                },
              },
            ],
          }
        : {
            message: this.string.notification.cannotStart,
            type: 'error',
          };

      vm.notificationCreate(toast);
    },

    /**
     * Save the filter validation state
     */
    setFilterValidation(isValid: boolean, requiredTerms: RequiredTerms): void {
      this.isFilterValid = isValid;
      this.requiredTerms = requiredTerms;
    },
  },
});

export { OExport };
export default OExport;
