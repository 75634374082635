





































import Vue from 'vue';

// lib
import {
  loginRedirectUrl,
  loginRedirectHttpMethod,
  isExternal,
  AuthProvider,
} from '@/service';

const OAuthProviders = Vue.extend({
  name: 'o-auth-providers',

  props: {
    providers: {
      type: Array as () => AuthProvider[],
      required: true,
    },
  },

  // single provider redirection is handled in login screen router guard

  methods: {
    loginRedirectUrl,
    loginRedirectHttpMethod,
    /**
     * External auth providers require a re-direct to an external
     * service for the user to authenticate.
     */
    isExternal,
  },
});

export { OAuthProviders };
export default OAuthProviders;
