import Vue from 'vue';
import { I8Renderer } from 'i8-ui';

import { OButtonIntent, OButtonAction } from '@/component';

const OPageRenderer = Vue.extend({
  components: {
    I8Renderer,
  },

  data() {
    return {
      // components declared here will be available to the renderer
      // in addition to the standard i8-ui set.
      customComponents: {
        ['o-button-intent']: OButtonIntent,
        ['o-button-action']: OButtonAction,
      },
    };
  },
});

export { OPageRenderer };
export default OPageRenderer;
