import Vue from 'vue';
import { PluginState, OPlugin } from '@/store';
import { OPluginIntent, OPluginContext } from './types';

// Save the top section nav items
export const pluginListSet = (
  state: PluginState,
  pluginList: OPlugin[] | undefined,
): void => {
  if (!pluginList) {
    return;
  }
  pluginList.forEach((plugin: OPlugin) => {
    plugin.status = 'initial';

    // save the config for this plugin
    Vue.set(state.plugin, plugin.id, plugin);

    // create an intent queue for this plugin
    Vue.set(state.intentQueue, plugin.id, []);
  });
};

export const setPluginContext = (
  state: PluginState,
  context: OPluginContext,
): void => {
  state.context = context;
};

// set a plugin status to 'connected'
export const pluginConnected = (state: PluginState, plugin: OPlugin): void => {
  state.plugin[plugin.id].status = 'connected';
};

// set a plugin status to 'error'
export const pluginError = (state: PluginState, plugin: OPlugin): void => {
  state.plugin[plugin.id].status = 'error';
};

interface IntentPayload {
  intent: OPluginIntent;
  pluginId: string;
  resolve: (value?: unknown) => void;
  reject: (value?: unknown) => void;
}

export const intentEnqueue = (
  state: PluginState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { intent, pluginId, resolve, reject }: IntentPayload,
): void => {
  if (pluginId) {
    state.intentQueue[pluginId].push(intent);
  } else {
    console.log('could not enqueue intent, plugin ID is empty: "', intent, '"');
  }
};
