





































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import filter from 'lodash/filter';
import omit from 'lodash/omit';
import get from 'lodash/get';

// Components
import { DocumentNav } from '@/component';
import { OAppPage, OPageConfig, OPageRenderer } from '@/mixin';
// Types
import { OverlayDocument } from '@/store';
import { View } from '@overlay/module-sdk';

export default Vue.extend({
  mixins: [OAppPage, OPageConfig, OPageRenderer],

  components: {
    DocumentNav,
  },

  beforeDestroy(): void {
    const vm = this as any;
    if (this.document && this.document.id) {
      vm.unsubscribe({ tags: [`document=${this.document.id}`] });
      vm.unsubscribe({ tags: [`document_event=${this.document.id}`] });
    }
  },

  computed: {
    ...mapGetters('document', ['documentByIdGet']),
    ...mapGetters('config', ['documentDetailViewsGet']),
    ...mapGetters('user', ['userHas']),

    /**
     * Configurd detail views for this document.
     *
     * These are configured in the appropriate `config.json` file.
     *
     * @return { DocumentDetailViewConfig[] }
     */
    detailViews(): View[] {
      const vm = this as any;
      const parent = this.$route.meta.parentId;
      const detailViews = vm.preprocessConfig(
        vm.documentDetailViewsGet(parent),
      );
      const permissibleViews = this.checkViewPermissions(detailViews);

      // only return _active_ views
      return filter(permissibleViews, 'active');
    },

    /**
     * Document object currently being looked at.
     *
     * This is passed to each detail view so they don't have to load it again.
     *
     * @return { OverlayDocument }
     */
    document(): OverlayDocument {
      const vm = this as any;
      return vm.documentByIdGet(this.documentId);
    },

    /**
     * Document ID for the docuemnt being viewed.
     *
     * This is loaded from a parameter in the URL.
     *
     * @return { string }
     */
    documentId(): string {
      return this.$route.params.id;
    },

    /**
     * return bool if url should be overwritten with module url.
     *
     *
     * @return { string }
     */
    moduleUrlOverride(): boolean {
      const vm = this as any;
      const config = vm.viewConfig(this.$route.meta.parentId);
      if (config.moduleUrlOverride) {
        return config.moduleUrlOverride as boolean;
      }
      return false;
    },

    /**
     * return moduleId defined in rfi config.
     *
     *
     * @return { string }
     */
    moduleId(): string {
      const vm = this as any;
      const config = vm.viewConfig(this.$route.meta.parentId);
      if (config.moduleId) {
        return config.moduleId as string;
      }
      return '';
    },

    /**
     * Array of I8ContextItems to show in the page header
     *
     * @return { Array } I8ContextItem[]
     */
    breadcrumbs(): object[] {
      const vm = this as any;
      const items: object[] = [];

      if (!this.document) {
        // we're not ready yet
        return items;
      }

      // current route config
      const view = vm.viewConfig(this.$route.meta.parentId);

      // parent route config
      let parentView = vm.viewConfig(view.parentViewId);

      // parent layout config
      let parentConfig = vm.parentConfig(parentView);

      const breadcrumb = get(parentConfig, 'breadcrumb');

      if (breadcrumb) {
        items.push(breadcrumb);
      } else {
        items.push({
          type: 'text',
          text: this.documentId,
        });
      }

      while (parentView) {
        parentConfig = vm.parentConfig(parentView);

        items.unshift({
          type: 'link',
          text: parentConfig ? parentConfig.name : parentView.id,
          location: {
            name: parentView.id,
          },
        });

        parentView = vm.viewConfig(parentView.parentViewId);
      }

      return items;
    },

    headerActions(): object[] {
      const vm = this as any;
      return vm.titleActionsSchema;
    },

    fullWidth(): boolean {
      return !!this.$route.meta.fullWidth;
    },

    /**
     * Context object for the renderer.
     *
     * @return { Object }
     */
    context(): object {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const vm = this as any;
      return {
        document: this.document,
      };
    },
  },

  methods: {
    ...mapActions('document', ['documentByIdLoad']),
    ...mapActions('plugin', ['setPluginContext']),
    ...mapActions('pubsub', ['subscribe', 'unsubscribe']),

    /**
     * Load all data needed for this view.
     *
     * Includes:
     *   - document currently being looked at
     *
     * @return { Promise<void> }
     */
    async loadData(): Promise<void> {
      const vm = this as any;

      try {
        vm.loadingStart();
        await vm.documentByIdLoad({
          documentId: this.documentId,
          moduleUrlOverride: this.moduleUrlOverride,
          moduleId: this.moduleId,
        });

        // ensure the plugins know about this document
        vm.setPluginContext({
          type: 'document',
          meta: omit(this.document, ['state']),
        });
      } catch (error) {
        vm.loadingError(error);
      } finally {
        vm.loadingComplete();
      }
    },
    checkViewPermissions(views: View[]): View[] {
      const allowedViews: View[] = [];
      const vm = this as any;

      views.forEach((view) => {
        if (!view.permissions || view.permissions.length == 0) {
          allowedViews.push(view);
          return;
        }

        let userHasCount = 0;
        view.permissions.forEach((permission) => {
          vm.userHas(permission) ? userHasCount++ : null;
        });
        view.permissions.length == userHasCount
          ? allowedViews.push(view)
          : null;
        return;
      });

      return allowedViews;
    },
  },

  watch: {
    /**
     * When the document ID changes, load all data for this view.
     *
     * This is done in a watcher instead of a lifecycle hook to work around
     * cases where vue re-uses the component instance.
     */
    documentId: {
      async handler(newDocId: string, oldDocId: string): Promise<void> {
        const vm = this as any;

        // remove previous document subscriptions
        if (oldDocId) {
          vm.unsubscribe({ tags: [`document=${oldDocId}`] });
          vm.unsubscribe({ tags: [`document_event=${oldDocId}`] });
        }

        // subscribe to updates for the new document
        if (newDocId) {
          // load data for the new document
          await this.loadData();

          // get websocket updates
          vm.subscribe({ tags: [`document=${newDocId}`] });
          vm.subscribe({ tags: [`document_event=${newDocId}`] });
        }
      },
      immediate: true,
    },
  },
});
