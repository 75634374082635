import { PluginState, OPlugin, PluginGetters } from '@/store';

import filter from 'lodash/filter';
import map from 'lodash/map';
import { OPluginIntent, IntentsByPluginId, OPluginContext } from './types';

import { TypedGetterMap } from '@/store/types';

type injectedGetter = TypedGetterMap<PluginGetters, PluginState>;

// get a list of configured plugins
export const pluginList = (state: PluginState): OPlugin[] => {
  return Object.values(state.plugin);
};

export const pluginContext = (state: PluginState): OPluginContext => {
  return state.context;
};

// get a list of configured plugins
export const pluginById =
  (state: PluginState) =>
  (pluginId: string): OPlugin | null => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.plugin.hasOwnProperty(pluginId)) {
      return state.plugin[pluginId];
    }

    return null;
  };

// get a list of configured plugins
export const pluginActions =
  (state: PluginState, getters: injectedGetter) =>
  (pluginId: string): string[] => {
    if (!pluginId) {
      return [];
    }

    const plugin = getters.pluginById(pluginId);

    if (plugin && plugin.actions && plugin.actions.length > 0) {
      return map(plugin.actions, 'name');
    }

    return [];
  };

// get a list of configured plugins
export const notInitialisedPluginList = (state: PluginState): OPlugin[] => {
  return filter(Object.values(state.plugin), ['status', 'initial']);
};

// queue up a new plugin action
export const intentQueue = (state: PluginState): IntentsByPluginId => {
  return state.intentQueue;
};

export const intentDequeue =
  (state: PluginState) =>
  (pluginId: string): OPluginIntent | undefined => {
    return state.intentQueue[pluginId].shift();
  };
