/**
 * Add the ability for `dataSrc` to be used in config.
 *
 * `dataSrc` allows extra data sources to be made available to a page.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue from 'vue';
import { mapActions } from 'vuex';

import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { PagedOverlayDocuments } from '@/service';
import {
  ExtraDataConfig,
  ExtraDataDocumentById,
  ExtraDataDocumentsByType,
  ExtraDataStoreAction,
  ExtraDataStoreGetter,
  PaginationOptions,
  ExtraDataLocalPageRequest,
} from './types';
import { OverlayActionIntent, OFileContent } from '../o-actions';

const OExtraData = Vue.extend({
  data() {
    return {
      extraData: {
        config: {} as ExtraDataConfig,
        // eslint-disable-next-line @typescript-eslint/ban-types
        data: {} as Record<string, Record<string, unknown>>,
        pagination: {} as Record<string, PaginationOptions>,
      },
    };
  },
  methods: {
    ...mapActions('file', ['fileByIdContent']),
    /**
     *  Load all extra data sources
     *
     * @param exdConfig
     */
    async exdLoadData(
      exdConfig: ExtraDataConfig,
      customHandlers: Record<string, (key: string) => Promise<void>>,
    ) {
      if (isEmpty(exdConfig)) {
        return;
      }

      // save a local reference
      this.extraData.config = exdConfig;

      const handler: Record<string, (key: string) => Promise<void>> = {
        documentsByType: this.exdLoadDocumentsByType,
        documentById: this.exdLoadDocumentsById,
        ['plugin.intent']: this.exdLoadPluginIntent,
        overlayFileContent: this.exdLoadFileContent,
        storeAction: this.exdLoadStoreAction,
        storeGetter: this.exdLoadStoreGetter,
        ...customHandlers,
      };

      try {
        await Promise.all(
          Object.entries(exdConfig)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, item]) => !!handler[item.type])
            .map(([key, item]) => handler[item.type](key)),
        );
      } catch (error) {
        // we catch here because we don't want to bring down the entire page
        console.error('unable to load extra data', error);
      }
    },

    /**
     * Load data from an overlay plugin
     *
     * @param key
     */
    async exdLoadPluginIntent(key: string): Promise<void> {
      const vm = this as any;
      const config = this.extraData.config[key] as OverlayActionIntent;

      if (config.cancelIfEmptyPayload) {
        const notEmptyValues = pickBy(config.intent.payload, (x) =>
          Array.isArray(x) ? x.length : x,
        );

        if (isEmpty(notEmptyValues)) {
          console.log('cancelling');

          return;
        }
      }

      const data = await vm.intentEnqueue(config.intent);
      this.$set(this.extraData.data, key, data);
    },

    /**
     * Load file contents from the file api
     *
     * @param key
     */
    async exdLoadFileContent(key: string): Promise<void> {
      const vm = this as any;
      const config = this.extraData.config[key] as OFileContent;
      const data = await vm.fileByIdContent(config.fileId);
      this.$set(this.extraData.data, key, data);
    },

    /**
     * Load a list of documents by type
     * @param key
     */
    async exdLoadDocumentsByType(key: string): Promise<void> {
      const vm = this as any;
      const config = this.extraData.config[key] as ExtraDataDocumentsByType;

      if (!this.extraData.pagination[key]) {
        // this is the first time querying this data source so we need to
        // create a new pagination object for it
        this.$set(this.extraData.pagination, key, {
          pageNum: 1,
          cursors: [],
          hasNext: false,
          hasPrev: false,
        } as PaginationOptions);
      }

      const pagination = this.extraData.pagination[key];

      if (config.cancelIfEmptyQuery) {
        const configQuery = omit(config.query, 'type');
        const notEmptyValues = pickBy(configQuery, (x) =>
          Array.isArray(x) ? x.length : x,
        );

        if (isEmpty(notEmptyValues)) {
          return;
        }
      }

      const apiQuery = {
        ...config.query, // api query declared in config
        cursor: pagination.cursors[pagination.pageNum],
      };

      const documents: PagedOverlayDocuments = await vm.documentsByTypeLoad({
        query: {
          apiQuery: pickBy(apiQuery, identity),
          request: config.request,
          skipCommit: true,
        },
        moduleUrlOverride:
          vm.moduleUrlOverride ||
          vm.routeConfig?.moduleUrlOverride ||
          vm.viewConfig(vm.$route.meta.parentId)?.moduleUrlOverride ||
          undefined,
        moduleId: vm.moduleId || vm.routeConfig?.moduleId || vm.viewConfig(vm.$route.meta.parentId)?.moduleId || undefined,
      });

      // save the new page of data
      this.$set(this.extraData.data, key, documents);

      // update pagination data
      pagination.cursors[pagination.pageNum + 1] = documents.next;
      pagination.hasNext = this.exdHasNext(key);
      pagination.hasPrev = this.exdHasPrev(key);
    },

    /**
     * Load a single document by ID
     * @param key
     */
    async exdLoadDocumentsById(key: string): Promise<void> {
      const vm = this as any;
      const config = this.extraData.config[key] as ExtraDataDocumentById;
      const document = await vm.documentByIdLoad({ documentId: config.id });
      this.$set(this.extraData.data, key, document);
    },

    /**
     * Load content from a store action
     * @param key
     */
    async exdLoadStoreAction(key: string): Promise<void> {
      const vm = this as any;
      const config = this.extraData.config[key] as ExtraDataStoreAction;
      const result = await vm.$store.dispatch(config.action, config.payload);
      this.$set(this.extraData.data, key, result.data);
    },

    /**
     * Load content from a store getter
     * @param key
     */
    async exdLoadStoreGetter(key: string): Promise<void> {
      const vm = this as any;
      const config = this.extraData.config[key] as ExtraDataStoreGetter;
      let getter = vm.$store.getters[config.getter];
      if (typeof getter == 'function') {
        getter = getter(config.params);
      }
      this.$set(this.extraData.data, key, getter);
    },

    /**
     * Is there a _next_ page of data available?
     *
     * @return { boolean }
     */
    exdHasNext(key: string): boolean {
      const pagination = this.extraData.pagination[key];
      if (pagination === undefined) {
        return false;
      }
      return !!pagination.cursors[pagination.pageNum + 1];
    },

    /**
     * Is there a _previous_ page of data available?
     *
     * @return { boolean }
     */
    exdHasPrev(key: string): boolean {
      const pagination = this.extraData.pagination[key];
      if (pagination === undefined) {
        return false;
      }
      return !!pagination.cursors[pagination.pageNum];
    },

    /**
     * Load the _next_ page of data
     *
     * @param key the key of the data source
     */
    exdNextPage({ dataSrcName }: { dataSrcName: string }): void {
      if (!this.exdHasNext(dataSrcName)) {
        // there is no next page
        return;
      }

      const pagination = this.extraData.pagination[dataSrcName];
      pagination.pageNum++;
      this.exdLoadDocumentsByType(dataSrcName);
    },

    /**
     * Load the _previous_ page of data
     *
     * @param key the key of the data source
     */
    exdPrevPage({ dataSrcName }: { dataSrcName: string }): void {
      if (!this.exdHasPrev(dataSrcName)) {
        // there is no prev page
        return;
      }

      const pagination = this.extraData.pagination[dataSrcName];
      pagination.pageNum--;
      this.exdLoadDocumentsByType(dataSrcName);
    },

    /**
     * Load a page of data
     */
    exdLocalPage(req: ExtraDataLocalPageRequest): void {
      if (!has(this.extraData.pagination, req.dataSrcName)) {
        this.$set(this.extraData.pagination, req.dataSrcName, {});
      }

      this.$set(
        this.extraData.pagination[req.dataSrcName],
        'pageNum',
        req.pageNum,
      );
      this.$set(this.extraData.data, req.dataSrcName, req.data);
    },
  },
});

export { OExtraData };
export default OExtraData;
