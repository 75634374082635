import axios, { AxiosResponse, AxiosError } from 'axios';
import { ApiService } from '@/service/types';
import { SESSION_CHECK } from './auth.service';
import endsWith from 'lodash/endsWith';
import { store } from '@/store';
import { ModuleInfo } from '@overlay/module-sdk';

export const BASE_URL = '/api/v1';
axios.defaults.baseURL = BASE_URL;

export const Api = {
  authTokenSet(token: string) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  },

  // HTTP GET
  get: axios.get,

  // HTTP POST
  post: axios.post,

  // HTTP PUT
  put: axios.put,

  // HTTP DELETE
  delete: axios.delete,

  getModuleUrl(moduleId: string): string {
    const modules = store.getters['config/modules'] as ModuleInfo[];
    let foundUrl = '';
    modules.forEach((module) => {
      if (module.id == moduleId) {
        foundUrl = module.base_url;
      }
    });
    return foundUrl;
  },

  getToken(): string {
    return store.getters['auth/session'].token;
  },
} as ApiService;

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

// Response interceptor
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const isSessionCheck = endsWith(error.config?.url, SESSION_CHECK);
    const isUnauthorized = error.response && error.response.status === 401;

    if (isUnauthorized && !isSessionCheck) {
      // this will trigger a redirect to the login screen
      // TODO: refresh the page and redirect to the current page once backend ready
      // reload page to redirect to login screen
      window.location.href = '/logout';
    }

    return Promise.reject(error);
  },
);
