import { AuthContext } from '@/store';
import { Auth, UserCredentials, AuthProvider } from '@/service';

// Login a user using a username and password
export const loginInternal = async (
  ctx: AuthContext,
  user: UserCredentials,
): Promise<void> => {
  const response = await Auth.loginInternal(user);
  ctx.commit('loggedIn', response.data);
  return response.data;
};

// load the current user session
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sessionCurrent = async (ctx: AuthContext): Promise<any> => {
  const response = await Auth.current();
  ctx.commit('loggedIn', response.data);
  return response.data;
};

// Initiate external authentication
export const loginProvidersLoad = async (ctx: AuthContext): Promise<void> => {
  const response = await Auth.getProviders();
  ctx.commit('providersListSet', response.data as AuthProvider[]);
  return response.data;
};

// Logout the currently logged in user
export const logout = async (ctx: AuthContext): Promise<void> => {
  const response = await Auth.logout();
  ctx.commit('loggedOut');
  return response.data;
};

export const logoutFromProvider = async (ctx: AuthContext): Promise<void> => {
  const response = await Auth.logout();
  ctx.commit('loggedOut');
  window.location.href = response.data.redirect_url;
};

// Recover a user's password
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const passwordRecover = async (ctx: AuthContext): Promise<void> => {
  console.warn('passwordRecover is not implemented');
  await Auth.passwordRecover('', {});
};

// Reset a user's password
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const passwordReset = async (ctx: AuthContext): Promise<void> => {
  console.warn('passwordReset is not implemented');
  await Auth.passwordReset({});
};
