import {
  ConfigState,
  AppNavConfig,
  AppNavItem,
  EventTypeConfig,
  UIConfig,
} from './types';

import find from 'lodash/find';
import filter from 'lodash/filter';
import { View, ModuleInfo } from '@overlay/module-sdk';

export const name = (state: ConfigState): string | null => {
  if (!state.ui) {
    return null;
  }

  return state.ui.name ?? null;
};

export const modules = (state: ConfigState): ModuleInfo[] | null => {
  if (!state.ui) {
    return null;
  }

  return state.ui.modules ?? null;
};

// Get a document list schema by document type
export const navItems = (state: ConfigState): AppNavConfig => {
  return state.globalNav;
};

export const allViewConfig = (state: ConfigState): View[] => {
  return Object.values(state.view);
};

export const viewConfig =
  (state: ConfigState) =>
  (viewId: string, parentViewId?: string): View | undefined => {
    if (!parentViewId) {
      return state.view[viewId];
    }

    const parent = state.view[parentViewId];
    if (!parent) {
      console.warn(`config not found for parent ${parentViewId} of ${viewId}`);
      return;
    }

    if (!parent.views || parent.views.length === 0) {
      console.warn(`${parent.id} doesn't have any child views`);
      return;
    }

    return find(parent.views, ['id', viewId]);
  };

// Get a render schema for a view
export const viewSchemaGet =
  (state: ConfigState) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  (viewId: string): any => {
    return state.screenSchema[viewId];
  };

// Get a document list schema by document type
export const eventTypes = (state: ConfigState): EventTypeConfig[] => {
  return state.eventTypes;
};

// Get application metadata
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const applicationMeta = (state: ConfigState): Record<string, any> => {
  return state.applicationMeta;
};

// Get UI config
export const uiConfig = (state: ConfigState): UIConfig | undefined => {
  return state.ui;
};

// Return the nav items for the top of the global nav
export const navTopItems = (state: ConfigState): AppNavItem[] => {
  return filter(
    state.globalNav.topItems,
    // eslint-disable-next-line no-prototype-builtins
    (x) => !x.hasOwnProperty('active') || x.active !== false,
  );
};

// Return the nav items for the bottom of the global nav
export const navBottomItems = (state: ConfigState): AppNavItem[] => {
  return filter(
    state.globalNav.bottomItems,
    // eslint-disable-next-line no-prototype-builtins
    (x) => !x.hasOwnProperty('active') || x.active !== false,
  );
};

// Return the configured logout route
export const logoutRoute = (state: ConfigState): string | undefined => {
  return state.globalNav.logoutRoute;
};

// Return the schema for nav
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const navSchema = (state: ConfigState): any => {
  return state.globalNav.schema;
};

// Get a document overview schema by document type
export const documentDetailViewsGet =
  (state: ConfigState) =>
  (parentId: string): View[] => {
    const view = state.view[parentId];

    if (!view || view.type !== 'document') {
      return [];
    }
    return view.views || [];
  };

// Get a document overview schema by document type
export const eventConfigGet =
  (state: ConfigState) =>
  (eventType: string): EventTypeConfig | undefined => {
    return find(state.eventTypes, ['type', eventType]);
  };

// Get a render schema for an event
export const eventBodySchemaGet =
  (state: ConfigState) =>
  (eventType: string): EventTypeConfig => {
    return state.eventBodySchema[eventType];
  };

// Get a render schema for an event
export const eventFooterSchemaGet =
  (state: ConfigState) =>
  (eventType: string): EventTypeConfig => {
    return state.eventFooterSchema[eventType];
  };

// Get the global render context
export const globalRenderContext = (
  state: ConfigState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
  return state.globalRenderContext;
};

// Get the UI config flag to apply the whitelabel variant
export const isWhitelabel = (state: ConfigState): boolean => {
  return !!state.ui?.theme?.whitelabel;
};

// Get the solution name for the current UI config
export const themeSolutionName = (state: ConfigState): string | undefined => {
  return state.ui?.theme?.solutionName;
};

// Get the favicon for the current UI config
export const themeFavicon = (state: ConfigState): string | undefined => {
  return state.ui?.theme?.favicon;
};

// Get the theme name for the current UI config
export const themeName = (state: ConfigState): string | undefined => {
  return state.ui?.theme?.themeName;
};
