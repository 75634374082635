import { Store } from 'vuex';
import omit from 'lodash/omit';

export const PluginPlugin = (store: Store<unknown>): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store.subscribe((mutation, state: any) => {
    if (mutation.type !== 'document/documentsByIdSet') {
      return;
    }

    if (state.plugin.context.type !== 'document') {
      return;
    }

    if (state.plugin.context.meta.id === mutation.payload.id) {
      store.dispatch('plugin/setPluginContext', {
        type: 'document',
        meta: omit(mutation.payload, ['state']),
      });
    }
  });
};
