import { Module } from 'vuex';

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import { OverlayState } from './types';
import { AppNavItem } from '../config/types';

export * from './types';

export const overlay: Module<OverlayState, unknown> = {
  namespaced: true,
  state: {
    notifications: [],
    isReady: false,
    dialog: {
      show: false,
      options: {},
    },
    dialogByNavItem: {} as AppNavItem,
  } as OverlayState,
  actions,
  mutations,
  getters,
};

export type OverlayGetters = typeof getters;
export type OverlayActions = typeof actions;
export type OverlayMutations = typeof mutations;
